import React, {useEffect, useState} from 'react';
import {BsInstagram, BsList, BsXSquare} from 'react-icons/bs';
import {Link, useLocation} from 'react-router-dom';
import {DarkModeSwitch} from 'react-toggle-dark-mode';
import {useLocalStorage} from 'usehooks-ts';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import {menus} from 'src/constant/menus';
import {analytics} from '../../config/firebase';
import {logEvent} from 'firebase/analytics';

import '../../styles/layouts.css';

const Layouts = ({children, fullwidth=false}) => {
  const [showNavbar, setShowNavbar] = useState(false);
  const location = useLocation();

  const [isDarkMode, setDarkMode] = useLocalStorage('darkTheme', false);

  const toggleDarkMode = checked => {
    if (checked) {
      setDarkMode(true);
      document.documentElement.classList.add('dark');
      document.documentElement.classList.remove('light');

      logEvent(analytics, 'dark_mode_enabled');
    } else {
      setDarkMode(false);
      document.documentElement.classList.remove('dark');
      document.documentElement.classList.add('light');

      logEvent(analytics, 'light_mode_enabled');
    }
  };

  useEffect(() => {
    if (
      isDarkMode ||
      (!('darkTheme' in localStorage) &&
        window.matchMedia('(prefers-color-scheme: dark)').matches)
    ) {
      setDarkMode(true);
      document.documentElement.classList.add('dark');
      document.documentElement.classList.remove('light');
    } else {
      setDarkMode(false);
      document.documentElement.classList.remove('dark');
      document.documentElement.classList.add('light');
    }
  }, []);

  return (
    <div className="layouts">
      <div className="nav">
        <div className="container nav__container">
          <button
            onClick={() => setShowNavbar(!showNavbar)}
            className="humburger"
          >
            <BsList
              className={`humburger__icon${
                showNavbar ? " humburger__icon--hidden" : ""
              }`}
              size={24}
            />
            <span className="humburger__label" />
            <BsXSquare
              className={`humburger__icon${
                showNavbar ? "" : " humburger__icon--hidden"
              }`}
              size={24}
            />
            <span className="humburger__label">Menu</span>
          </button>

          <div className="nav__left">
            <Link
              to="/"
              className="nav__logo"
              onClick={() => logEvent(analytics, "logo_clicked")}
            >
              <img
                src={isDarkMode ? "/logo_white.png" : "/logo.png"} // Use different logo based on isDarkMode
                alt="Logo"
                style={{ width: "24px", height: "24px" }}
              />

              <b style={{ color: isDarkMode ? "white" : "#3a63d3" }}>
                RentDaddy
              </b>
            </Link>
            <ul className={`nav__menu ${showNavbar && "nav__menu_show"}`}>
              {menus.map((menu, idx) => {
                return (
                  <li key={idx}>
                    <Link
                      className={`${
                        location.pathname === menu.url && "active"
                      }`}
                      to={menu.url}
                    >
                      {menu.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="nav__right">
            <a
              href="https://www.instagram.com/rentdaddy.us/"
              target="_blank"
              rel="noreferrer"
              onClick={() => logEvent(analytics, "instagram_link_clicked")}
            >
              <BsInstagram size={18} />
            </a>
            <div className="nav__right_divider" />
            <div>
              <DarkModeSwitch
                checked={isDarkMode}
                onChange={toggleDarkMode}
                size={20}
                moonColor={"#3a63d3"}
                sunColor={"orange"}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={`${fullwidth ? null : "tw-w-5/6 tw-mx-auto"}`}>
        {children}
      </div>

      {/* Footer starts from here */}

      <div className="footer">
        <div className="container">
          <p>RentDaddy LLC 2023. All Rights Reserved.</p>
        </div>
      </div>
      <div className="tw-bg-[#171a1a]">
        {/* <div className="tw-pt-4 tw-grid sm:tw-grid-cols-2 sm:tw-px-20 lg:tw-flex lg:tw-justify-between xl:tw-px-48">
          <div className="tw-flex tw-gap-1 tw-justify-center tw-pb-4">
            <a href="/" className="tw-text-white tw-no-underline">
              Real Estate
            </a>
            <KeyboardArrowDownIcon className="tw-text-white tw-cursor-pointer" />
          </div>
          <div className="tw-text-[#34393b] tw-text-xl tw-mt-[-.2rem] tw-hidden">
            |
          </div>
          <div className="tw-flex tw-gap-1 tw-justify-center tw-pb-4">
            <a href="/" className="tw-text-white tw-no-underline">
              Rentals
            </a>
            <KeyboardArrowDownIcon className="tw-text-white tw-cursor-pointer" />
          </div>
          <div className="tw-text-[#34393b] tw-text-xl tw-mt-[-.2rem] tw-hidden">
            |
          </div>
          <div className="tw-flex tw-gap-1 tw-justify-center tw-pb-4">
            <a href="/" className="tw-text-white tw-no-underline">
              Mortgage Rates
            </a>
            <KeyboardArrowDownIcon className="tw-text-white tw-cursor-pointer" />
          </div>
          <div className="tw-text-[#34393b] tw-hidden tw-text-xl tw-mt-[-.2rem]">
            |
          </div>
          <div className="tw-flex tw-gap-1 tw-justify-center tw-pb-4">
            <a href="/" className="tw-text-white tw-no-underline">
              Browse Homes
            </a>
            <KeyboardArrowDownIcon className="tw-text-white tw-cursor-pointer" />
          </div>
        </div> */}
        {/* <hr className="tw-bg-[#34393b] tw-border-none tw-h-[1px]" /> */}
        <div className="tw-bg-[#171a1a]">
          <div className="tw-grid sm:tw-grid-cols-3 sm:tw-px-8 tw-gap-4 tw-text-center tw-pt-4 lg:tw-flex lg:tw-justify-center xl:tw-px-48">
            {/* <div>
              <a href="/" className="tw-text-white tw-no-underline">
                About
              </a>
            </div>
            <div>
              <a href="/" className="tw-text-white tw-no-underline">
                Zestimates
              </a>
            </div>
            <div>
              <a href="/" className="tw-text-white tw-no-underline">
                Research
              </a>
            </div>
            <div>
              <a href="/" className="tw-text-white tw-no-underline">
                Careers
              </a>
            </div>
            <div>
              <a href="/" className="tw-text-white tw-no-underline">
                Help
              </a>
            </div>
            <div>
              <a href="/" className="tw-text-white tw-no-underline">
                Advertise
              </a>
            </div>
            <div>
              <a href="/" className="tw-text-white tw-no-underline">
                Fair Housing Guide
              </a>
            </div> */}
            <div>
              <Link
                className={`tw-text-white tw-no-underline`}
                to={"/terms-and-conditions"}
              >
                Terms of use
              </Link>
            </div>

            <div>
              <Link
                to={"/privacy-policy"}
                className="tw-text-white tw-no-underline"
              >
                Privacy Policy
              </Link>
            </div>

            <div>
              <Link
                to={"/disclaimer"}
                className="tw-text-white tw-no-underline"
              >
                Disclaimer
              </Link>
            </div>
          </div>
          {/* <div>
            <div className="tw-grid sm:tw-grid-cols-2 sm:tw-px-12 tw-gap-4 tw-text-center tw-pt-4 lg:tw-flex lg:tw-justify-between lg:tw-px-72 xl:tw-px-[32rem]">
              <div>
                <a href="/" className="tw-text-white tw-no-underline">
                  Cookie Preference
                </a>
              </div>
              <div>
                <a href="/" className="tw-text-white tw-no-underline">
                  Blog
                </a>
              </div>
              <div>
                <a href="/" className="tw-text-white tw-no-underline">
                  AI
                </a>
              </div>
              <div>
                <a href="/" className="tw-text-white tw-no-underline">
                  Mobile Apps
                </a>
              </div>
            </div>
            <div className="tw-grid sm:tw-grid-cols-2 sm:tw-px-12 tw-gap-4 tw-text-center tw-pt-4 lg:tw-flex lg:tw-justify-between lg:tw-px-72 xl:tw-px-[30rem]">
              <div>
                <a href="/" className="tw-text-white tw-no-underline">
                  Trulia
                </a>
              </div>
              <div>
                <a href="/" className="tw-text-white tw-no-underline">
                  StreetEasy
                </a>
              </div>
              <div>
                <a href="/" className="tw-text-white tw-no-underline">
                  HotPads
                </a>
              </div>
              <div>
                <a href="/" className="tw-text-white tw-no-underline">
                  Out East
                </a>
              </div>
              <div>
                <a href="/" className="tw-text-white tw-no-underline">
                  ShowingTime+
                </a>
              </div>
            </div>
          </div> */}

          <div className="tw-flex tw-justify-center tw-text-center tw-pt-10">
            <Link
              className="tw-text-[#5f95c6] tw-no-underline tw-pb-10"
              to={"/terms-and-conditions"}
            >
              Do Not Sell or Share My Personal Information &rarr;
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layouts;
