import {
	addDoc,
	collection,
	doc,
	getDoc,
	getDocs,
	limit,
	orderBy,
	query,
} from 'firebase/firestore';

import { db } from 'src/config/firebase';

const RentalsService = {};

RentalsService.getConfigs = async() => {
	const configDoc = await getDoc(doc(db, 'config', 'addRental'));
	if (configDoc.exists()) {
		return Promise.resolve(configDoc.data());
	} else {
		return Promise.reject('Unable to retrieve configs.');
	}
};

RentalsService.getDefaultCities = async() => {
	const configDoc = await getDoc(doc(db, 'config', 'dashboard'));
	if (configDoc.exists()) {
		return Promise.resolve(configDoc.data()?.prePopulatedCities);
	} else {
		return Promise.reject('Unable to retrieve configs.');
	}
};

RentalsService.getMarkerColors = async() => {
	const configDoc = await getDoc(doc(db, 'config', 'markerColors'));
	if (configDoc.exists()) {
		localStorage.setItem('markerColors', JSON.stringify(configDoc.data()));
		return Promise.resolve(configDoc.data());
	} else {
		localData = localStorage.getItem('markerColors');
		if (localData) {
			return Promise.resolve(JSON.parse(localData));
		} else {
			return Promise.reject('Unable to retrieve configs.');
		}
	}
};

RentalsService.createRental = async data => {
	try {
		const docRef = await addDoc(collection(db, 'rentals'), {
			...data,
			createdAt: new Date(),
			modifyDate: new Date(),
		});
		return Promise.resolve(docRef);
	} catch (error) {
		return Promise.reject('Error adding document.');
	}
};
RentalsService.createEmail = async data => {
	try {
		const docRef = await addDoc(collection(db, 'emails'), {
			...data,
			createdAt: new Date(),
			modifyDate: new Date(),
		});
		return Promise.resolve(docRef);
	} catch (error) {
		return Promise.reject('Error adding document.');
	}
};
RentalsService.getRentals = async() => {
	const docsRef = collection(db, 'rentals');
	const q = query(docsRef, orderBy('createdAt', 'desc'), limit(2));
	const querySnapshot = await getDocs(q);
	const rentals = querySnapshot.docs.map(doc => {
		return {
			id: doc.id,
			...doc.data(),
		};
	});
	return rentals;
};

export default RentalsService;
