/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
import {collection, getDocs, query, where} from 'firebase/firestore';

import {db} from 'src/config/firebase';
import RentalsService from 'src/services/rentals';

export const useGetFirestoreWithPagination = collectionName => {
  const [allData, setAllData] = useState([]);
  const [markerColors, setMarkerColors] = useState({
    min: [],
    color: [],
  });

  const fetchAllData = async () => {
    const docsRef = collection(db, collectionName);
    const resMarkerColors = await RentalsService.getMarkerColors();
    setMarkerColors(resMarkerColors);
    const querySnapshot = await getDocs(docsRef);
    if (!querySnapshot.empty) {
      const newData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAllData(newData);
    }
  };

  const fetchDataWithFilter = async (data) => {
    try {
      const min_price = data?.price_min
        ? Number(data?.price_min?.replace(/[^0-9.-]+/g, ''))
        : undefined;
      const max_price = data?.price_max
        ? Number(data?.price_max?.replace(/[^0-9.-]+/g, ''))
        : undefined;

      const docsRef = collection(db, collectionName);
      let queryRef = docsRef;
      if (data.beds.length > 0 && data.property_type.length > 0) {
        queryRef = query(
          docsRef,
          where('beds', 'in', data.beds),
          where('propertyType', 'in', data.property_type),
        );
      } else if (data.beds.length > 0) {
        queryRef = query(docsRef, where('beds', 'in', data.beds));
      } else if (data.property_type.length > 0) {
        queryRef = query(
          docsRef,
          where('propertyType', 'in', data.property_type),
        );
      }
      // Include a check for the state
      if (data.state) {
        queryRef = query(
          queryRef,
          where('locationAddress.state', '==', data.state),
          );
      }

      const querySnapshot = await getDocs(queryRef);

      if (!querySnapshot.empty) {
        const newData = querySnapshot.docs
          .map(doc => ({
            id: doc.id,
            ...doc.data(),
          }))
          .filter(product => {
            const price = Number(product?.price?.replace(/[^0-9.-]+/g, ''));
            if (min_price !== undefined && max_price !== undefined) {
              return price >= min_price && price <= max_price;
            } else if (min_price !== undefined) {
              return price >= min_price;
            } else if (max_price !== undefined) {
              return price <= max_price;
            } else {
              return true;
            }
          });
        setAllData(newData);
      } else {
        setAllData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  return {
    allData,
    markerColors,
    fetchDataWithFilter,
    fetchAllData,
  };
};
