import React from 'react';
import {BrowserRouter, Routes} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';

import AuthRoutes from './router/AuthRoutes';
import RouteChangeTracker from './router/RouteChangeTracker';

import 'react-toastify/dist/ReactToastify.css';
import './styles/global.css';
import './styles/tailwind.css';

const App = () => {
  return (
    <React.Fragment>
      <BrowserRouter>
        <RouteChangeTracker />
        <Routes>{AuthRoutes}</Routes>
      </BrowserRouter>
      <ToastContainer />
    </React.Fragment>
  );
};

export default App;
