import React from 'react';
import { Controller } from 'react-hook-form';
import Select from 'react-select';

const MySelect = ({ options, id, control, isError, isMulti = false }) => {

	if (control) {
		return (
			<Controller
				name={ id }
				control={ control }
				render={ ({ field }) => (
					<Select
						{ ...field }
						isMulti={ isMulti }
						isClearable
						menuPortalTarget={ document.body }
						id={ id }
						options={ options }
						styles={ {
							control: (styles, { isFocused }) => ({
								...styles,
								borderWidth: '1px',
								minHeight: '46.5px',
								borderRadius: '8px',
								backgroundColor: 'var(--card)',
								border: `${
									isError
										? '1px solid #e33434'
										: isFocused
											? '1px solid #3A63D3'
											: '1px solid #F4F4F4'
								}`,
								boxShadow: '0 !important',
								'&:hover': {
									border: `${
										isError
											? '1px solid #e33434'
											: isFocused
												? '1px solid #3A63D3'
												: '1px solid #F4F4F4'
									}`,
								},
							}),
							menuPortal: base => ({ ...base, zIndex: 9999 }),
							menu: styles => ({
								...styles,
								background: 'var(--card)',
								borderColor: 'var(--text-color)',
							}),
							option: (styles, { isFocused, isSelected }) => ({
								...styles,
								backgroundColor:
                  (isFocused || isSelected) ? 'var(--blue)' : 'var(--card)',
								borderColor: 'var(--text-color)',
								color: (isFocused || isSelected) ? 'white' : 'var(--text-color)',
								cursor: 'pointer',
								'&:hover': {
									backgroundColor: 'var(--blue)',
									color: 'white'
								},
							}),
							singleValue: styles => ({
								...styles,
								color: 'var(--text-color)',
							}),
							input: styles => ({
								...styles,
								paddingTop: '0.25rem',
								paddingBottom: '0.25rem',
								color: 'var(--text-color)',
							}),
						} }
					/>
				) }
			/>
		);
	} else {
		return (
			<Select
				isMulti={ isMulti }
				isClearable
				menuPortalTarget={ document.body }
				id={ id }
				options={ options }
				styles={ {
					control: (styles, { isFocused }) => ({
						...styles,
						borderWidth: '1px',
						minHeight: '46.5px',
						borderRadius: '8px',
						backgroundColor: 'var(--card)',
						border: `${
							isError
								? '1px solid #e33434'
								: isFocused
									? '1px solid #3A63D3'
									: '1px solid #F4F4F4'
						}`,
						boxShadow: '0 !important',
						'&:hover': {
							border: `${
								isError
									? '1px solid #e33434'
									: isFocused
										? '1px solid #3A63D3'
										: '1px solid #F4F4F4'
							}`,
						},
					}),
					menuPortal: base => ({ ...base, zIndex: 9999 }),
					menu: styles => ({
						...styles,
						background: 'var(--card)',
						borderColor: 'var(--text-color)',
					}),
					option: (styles, { isFocused, isSelected }) => ({
						...styles,
						zIndex: 999,
						backgroundColor:
                  (isFocused || isSelected) ? 'var(--blue)' : 'var(--card)',
						borderColor: 'var(--text-color)',
						color: (isFocused || isSelected) ? 'white' : 'var(--text-color)',
						cursor: 'pointer',
						'&:hover': {
							backgroundColor: 'var(--blue)',
							color: 'white'
						},
					}),
					singleValue: styles => ({
						...styles,
						color: 'var(--text-color)',
					}),
					input: styles => ({
						...styles,
						paddingTop: '0.25rem',
						paddingBottom: '0.25rem',
						color: 'var(--text-color)',
					}),
				} }
			/>
		);
	}

};

export default MySelect;
