import React from 'react';
import { Link } from 'react-router-dom';
import { BsSearch, BsPlus } from 'react-icons/bs';

const HeroSection = ({ scrollToSection }) => {
  const handleScrollClick = () => {
    scrollToSection();
  };

  return (
    <>
      <div className="card tw-rounded-[10px] tw-bg-[url(/src/herosection/rent.jpg)] tw-bg-cover tw-bg-center tw-mt-4 tw-shadow-3xl tw-relative tw-darken-background">
        <div className="tw-darken-overlay"></div>
        <div className="tw-text-center tw-h-[80vh] tw-flex tw-flex-col tw-justify-evenly tw-px-2">
          <div>
            <h1 className="tw-text-[2rem] sm:tw-text-[2.5rem] tw-text-white tw-mb-6">
              Find Your Perfect Home: Rent with Ease
            </h1>
            <h2 className="tw-text-white tw-text-[1.4rem] sm:tw-text-[1.6rem]">
              Rental transparency at your fingertips
            </h2>
          </div>
          <div className="tw-flex tw-justify-center sm:tw-justify-around sm:tw-mt-0 tw-flex-col sm:tw-px-10 md:tw-px-20 sm:tw-flex-row tw-gap-y-4 tw-flex-wrap">
            <Link
              to="/add-rental"
              className="btn sm:tw-px-10 tw-px-2 tw-py-3 tw-mb-2 sm:tw-mb-0"
              onClick={() => logEvent(analytics, 'add_rental_clicked')}
            >
              <BsPlus />
              <span className="tw-ml-1">Add your Rental</span>
            </Link>
            <Link
              to={'/rental-listings'}
              className="btn sm:tw-px-10 tw-px-2 tw-py-3"
              onClick={handleScrollClick}
            >
              <BsSearch />
              <span className="tw-ml-2">Find Rentals</span>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
