import React from 'react';
import { Route } from 'react-router-dom';

import {
	AddRental, Contact, Home, OurMission, RentalListings
} from 'src/pages';
import RentalDetails from 'src/pages/RentalListings/rental_details';
import Disclaimer from 'src/pages/disclaimer';
import PrivacyPolicy from 'src/pages/privacy-policy';
import TermsAndConditions from 'src/pages/terms-and-conditions';

const AuthRoutes = [
  <Route path="/" key="/" element={<Home />} />,
  <Route path="/add-rental" key="/add-rental" element={<AddRental />} />,
  <Route path="/our-mission" key="/our-mission" element={<OurMission />} />,
  <Route
    path="/rental-listings"
    key="/rental-listings"
    element={<RentalListings />}
  />,
  <Route path="/contact" key="/contact" element={<Contact />} />,
  <Route path="/disclaimer" key="/disclaimer" element={<Disclaimer />} />,
  <Route
    path="/terms-and-conditions"
    key="/terms-and-conditions"
    element={<TermsAndConditions />}
  />,
  <Route
    path="/privacy-policy"
    key="/privacy-policy"
    element={<PrivacyPolicy />}
  />,
  <Route path="/rental/:rental_id" key="/rental" element={<RentalDetails />} />,
];

export default AuthRoutes;
