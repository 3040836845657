import React from 'react';
import { Layouts } from 'src/components';


const Disclaimer = () => {
  return (
    <Layouts>
      <div className=" tw-shadow-md tw-rounded-md tw-my-8 tw-p-4">
        <p className="tw-text-3xl tw-font-bold tw-text-center">Disclaimer</p>
        <p className="tw-pt-3 ">Last Updated: June 1, 2023</p>

        <p className="tw-pt-3 ">
          This website and mobile application (collectively referred to as the
          &apos;Service&apos;) are owned and operated by RentDaddy LLC
          (&apos;RentDaddy,&apos; &apos;we,&apos; &apos;us,&apos; or
          &apos;our&apos;). This Disclaimer governs your use of our Service.
        </p>

        <p className="tw-pt-3 ">
          NoFinancial or Legal Advice: The information provided on our Service
          is for informational purposes only and does not constitute financial,
          legal, or other professional advice. You should not rely on this
          information as a substitute for, nor does it replace, professional
          financial or legal advice.
        </p>

        <p className="tw-pt-3 ">
          NoWarranty: RentDaddy makes no warranties or representations about the
          accuracy or completeness of the Service&apos;s content or the content
          of any linked sites. Everything on the Service and all services
          provided through it are provided &apos;as is&apos; without warranty of
          any kind.
        </p>
        <p className="tw-pt-3 ">
          Limitation of Liability: In no event will RentDaddy, its directors,
          employees, partners, agents, suppliers, or affiliates, be liable for
          any indirect, incidental, special, consequential, or punitive damages,
          including without limitation, loss of profits, data, use, goodwill, or
          other intangible losses, resulting from (i) your access to or use of
          or inability to access or use the Service; (ii) any conduct or content
          of any third party on the Service; (iii) any content obtained from the
          Service; and (iv) unauthorized access, use, or alteration of your
          transmissions or content, whether based on warranty, contract, tort
          (including negligence), or any other legal theory, whether or not we
          have been informed of the possibility of such damage, and even if a
          remedy set forth herein is found to have failed of its essential
          purpose.
        </p>
        <p className="tw-pt-3 ">
          External Links: Our Service may contain links to third-party websites
          or services that are not owned or controlled by RentDaddy. RentDaddy
          has no control over, and assumes no responsibility for, the content,
          privacy policies, or practices of any third-party websites or
          services. We do not endorse the offerings of any of these
          entities/individuals or their websites.
        </p>
        <p className="tw-pt-3 ">
          Changes: We reserve the right to modify this Disclaimer at any time,
          effective upon posting of an updated version on the Service. It is
          your responsibility to regularly review this Disclaimer to stay
          informed of updates.
        </p>
        <p className="tw-pt-3 ">
          Agreement: By using our Service, you accept and agree to this
          Disclaimer and assume all liability for any loss, harm, damage, costs,
          or expense, and waive any and all causes of action, connected with any
          claim you may have against us in respect thereof.
        </p>
        <p className="tw-pt-3 ">
          If you have any questions or concerns about this Disclaimer, please
          contact us at{' '}
          <a href="mailto:rentdaddyinfo@gmail.com">rentdaddyinfo@gmail.com</a>
        </p>
      </div>
    </Layouts>
  );
};

export default Disclaimer;
