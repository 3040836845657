/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { Grid } from 'gridjs-react';

const TableComponent = props => {
	const gridRef = useRef();

	useEffect(() => {
		const gridjsInstance = gridRef?.current?.getInstance();
		gridjsInstance?.on('rowClick', (args, data) => {
			props.mapRef?.current?.flyTo({
				bearing: 0,
				essential: true,
				center: data._cells[0].data,
				zoom: 15,
			});
		});
	}, []);

	return (
    <div className="table-container">
      <Grid
        ref={gridRef}
        data={props.data}
        resizable={true}
        columns={props.columns}
        search={false}
        fixedHeader={true}
        autoWidth={true}
        // height={ 390 }
        pagination={{
          limit: 100,
        }}
        className={{td: 'tw-w-48 w-48'}}
      />
    </div>
  );
};

function compare(prevProps, nextProps) {
	return JSON.stringify(prevProps?.data) === JSON.stringify(nextProps?.data);
}

const Table = React.memo(TableComponent, compare);

export default Table;
