// Import the functions you need from the SDKs you need
import { getAnalytics } from '@firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const FIREBASE_CONFIG = {
	apiKey: 'AIzaSyAqVyhSvJRcI-aSZGl8vMXde3K1UEbcFEo',
	authDomain: 'rentdaddy-f72f2.firebaseapp.com',
	projectId: 'rentdaddy-f72f2',
	storageBucket: 'rentdaddy-f72f2.appspot.com',
	messagingSenderId: '177647220373',
	appId: '1:177647220373:web:5832b3d8e925aeda3a0d9c',
	measurementId: 'G-L6L9LTW1RD',
};

// Initialize Firebase

const app = initializeApp(FIREBASE_CONFIG);
// Export firestore database
// It will be imported into your react app whenever it is needed
export const db = getFirestore(app);
export const analytics = getAnalytics(app);
