import {
  addDoc,
  collection,
  doc,
  documentId,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "src/config/firebase";
const RentalsListingService = {};

RentalsListingService.getRentalList = async () => {
  const docsRef = collection(db, "rental-listings");
  const q = query(docsRef, orderBy("createdAt", "desc"));
  const querySnapshot = await getDocs(q);
  const rentals = querySnapshot.docs.map((doc) => {
    return {
      id: doc.id,
      ...doc.data(),
    };
  });
  return rentals;
};

RentalsListingService.getRentalDetails = async (rental_id) => {
  const docRef = doc(db, "rental-listings", rental_id);
  const docSnapshot = await getDoc(docRef);

  if (docSnapshot.exists()) {
    const rental = {
      id: docSnapshot.id,
      ...docSnapshot.data(),
    };
    return rental;
  } else {
    // Document does not exist
    return null;
  }
};

RentalsListingService.getRentalsByFilter = async (filter_query) => {
  console.log(`🚀 ~ file: rental-listings.js:46 ~ RentalsListingService.getRentalsByFilter= ~ filter_query:`, filter_query);
  // const docRef = doc(db, "rental-listings");
  // const q = query(docRef, where());
  // const querySnapshot = await getDocs(q);
  // const rentals = querySnapshot.docs.map((doc) => {
  //   return {
  //     id: doc.id,
  //     ...doc.data(),
  //   };
  // });
  // return rentals;
};

export default RentalsListingService;
