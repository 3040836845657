import React, { useEffect, useState } from 'react';

import building1Image from 'src/buildings/buildings_1.png';
import building2Image from 'src/buildings/buildings_2.png';
import building3Image from 'src/buildings/buildings_3.png';
import { Layouts } from 'src/components';

import 'src/styles/ourmission.css';

const OurMission = () => {
	const images = [building1Image, building2Image, building3Image];
	const [currentImageIndex, setCurrentImageIndex] = useState(0);

	const cycleImages = () => {
		setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
	};

	useEffect(() => {
		const timer = setInterval(() => {
			cycleImages();
		}, 3000); // Change the time interval (in milliseconds) as needed

		return () => {
			clearInterval(timer);
		};
	}, []);
	return (
    <Layouts>
      <div className="container card our-mission tw-px-10">
        <div className="image-container tw-mt-10">
          <img src={images[currentImageIndex]} alt="Our Mission" />
        </div>

        <h1>Our Mission</h1>
        <p className="tw-mt-4">
          Welcome to Rent Daddy - a platform dedicated to helping renters find
          the best deals and fair prices in the real estate rental market.
          <br />
          <br />
          We understand that finding affordable and fair rental prices can be a
          challenging task, especially in today&apos;s market. That&apos;s why
          our mission is to empower renters with the information and data they
          need to make informed decisions about their housing options.
          <br />
          <br />
          Our goal is to collect and analyze real estate rental market numbers
          from across the country to provide renters with the most accurate and
          up-to-date information about the market.{' '}
          <b>
            By doing so, we hope to create a more transparent rental market
            where renters can find the best deals and negotiate fair prices with
            landlords.
          </b>
          <br />
          <br />
          At Rent Daddy, we believe that everyone deserves a comfortable and
          affordable place to call home, and we are committed to making that a
          reality for renters everywhere. Join us in our mission to create a
          fair and transparent rental market for all.
          <br />
          <br />
          We are excited for your new beginnings,
          <br />
          <br />- RentDaddy Team
        </p>
      </div>
    </Layouts>
  );
};

export default OurMission;
