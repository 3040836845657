import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../config/firebase';

function RouteChangeTracker() {
  const location = useLocation();

  useEffect(() => {
    logEvent(analytics, 'page_view', {
      page_location: location.pathname,
      page_title: document.title,
    });
  }, [location]);
  return null;
}

export default RouteChangeTracker;
