import React from 'react';
import { Controller } from 'react-hook-form';
import Select from 'react-select';

export const STATES_OPTIONS = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' }
];


export const NewStateLists=[
  {
      "value": "AK",
      "zoom":3,
      "latitude": "63.588753",
      "longitude": "-154.493062",
      "label": "Alaska"
  },
  {
      "value": "AL",
      "zoom":6,
      "latitude": "32.318231",
      "longitude": "-86.902298",
      "label": "Alabama"
  },
  {
      "value": "AR",
      "zoom":6,
      "latitude": "35.20105",
      "longitude": "-91.831833",
      "label": "Arkansas"
  },
  {
      "value": "AZ",
      "zoom":5,
      "latitude": "34.048928",
      "longitude": "-111.093731",
      "label": "Arizona"
  },
  {
      "value": "CA",
      "zoom":5,
      "latitude": "36.778261",
      "longitude": "-119.417932",
      "label": "California"
  },
  {
      "value": "CO",
      "zoom":5,
      "latitude": "39.550051",
      "longitude": "-105.782067",
      "label": "Colorado"
  },
  {
      "value": "CT",
      "zoom":6,
      "latitude": "41.603221",
      "longitude": "-73.087749",
      "label": "Connecticut"
  },
  {
      "value": "DC",
      "zoom":6,
      "latitude": "38.905985",
      "longitude": "-77.033418",
      "label": "District of Columbia"
  },
  {
      "value": "DE",
      "zoom":6,
      "latitude": "38.910832",
      "longitude": "-75.52767",
      "label": "Delaware"
  },
  {
      "value": "FL",
      "zoom":6,
      "latitude": "27.664827",
      "longitude": "-81.515754",
      "label": "Florida"
  },
  {
      "value": "GA",
      "zoom":5,
      "latitude": "32.157435",
      "longitude": "-82.907123",
      "label": "Georgia"
  },
  {
      "value": "HI",
      "zoom":6,
      "latitude": "19.898682",
      "longitude": "-155.665857",
      "label": "Hawaii"
  },
  {
      "value": "IA",
      "zoom":6,
      "latitude": "41.878003",
      "longitude": "-93.097702",
      "label": "Iowa"
  },
  {
      "value": "ID",
      "zoom":6,
      "latitude": "44.068202",
      "longitude": "-114.742041",
      "label": "Idaho"
  },
  {
      "value": "IL",
      "zoom":6,
      "latitude": "40.633125",
      "longitude": "-89.398528",
      "label": "Illinois"
  },
  {
      "value": "IN",
      "zoom":6,
      "latitude": "40.551217",
      "longitude": "-85.602364",
      "label": "Indiana"
  },
  {
      "value": "KS",
      "zoom":5,
      "latitude": "39.011902",
      "longitude": "-98.484246",
      "label": "Kansas"
  },
  {
      "value": "KY",
      "zoom":5,
      "latitude": "37.839333",
      "longitude": "-84.270018",
      "label": "Kentucky"
  },
  {
      "value": "LA",
      "zoom":6,
      "latitude": "31.244823",
      "longitude": "-92.145024",
      "label": "Louisiana"
  },
  {
      "value": "MA",
      "zoom":6,
      "latitude": "42.407211",
      "longitude": "-71.382437",
      "label": "Massachusetts"
  },
  {
      "value": "MD",
      "zoom":6,
      "latitude": "39.045755",
      "longitude": "-76.641271",
      "label": "Maryland"
  },
  {
      "value": "ME",
      "zoom":6,
      "latitude": "45.253783",
      "longitude": "-69.445469",
      "label": "Maine"
  },
  {
      "value": "MI",
      "zoom":5,
      "latitude": "44.314844",
      "longitude": "-85.602364",
      "label": "Michigan"
  },
  {
      "value": "MN",
      "zoom":5,
      "latitude": "46.729553",
      "longitude": "-94.6859",
      "label": "Minnesota"
  },
  {
      "value": "MO",
      "zoom":6,
      "latitude": "37.964253",
      "longitude": "-91.831833",
      "label": "Missouri"
  },
  {
      "value": "MS",
      "zoom":6,
      "latitude": "32.354668",
      "longitude": "-89.398528",
      "label": "Mississippi"
  },
  {
      "value": "MT",
      "zoom":5,
      "latitude": "46.879682",
      "longitude": "-110.362566",
      "label": "Montana"
  },
  {
      "value": "NC",
      "zoom":5,
      "latitude": "35.759573",
      "longitude": "-79.0193",
      "label": "North Carolina"
  },
  {
      "value": "ND",
      "zoom":5,
      "latitude": "47.551493",
      "longitude": "-101.002012",
      "label": "North Dakota"
  },
  {
      "value": "NE",
      "zoom":5,
      "latitude": "41.492537",
      "longitude": "-99.901813",
      "label": "Nebraska"
  },
  {
      "value": "NH",
      "zoom":6,
      "latitude": "43.193852",
      "longitude": "-71.572395",
      "label": "New Hampshire"
  },
  {
      "value": "NJ",
      "zoom":6,
      "latitude": "40.058324",
      "longitude": "-74.405661",
      "label": "New Jersey"
  },
  {
      "value": "NM",
      "zoom":5,
      "latitude": "34.97273",
      "longitude": "-105.032363",
      "label": "New Mexico"
  },
  {
      "value": "NV",
      "zoom":5,
      "latitude": "38.80261",
      "longitude": "-116.419389",
      "label": "Nevada"
  },
  {
      "value": "NY",
      "zoom":5,
      "latitude": "43.299428",
      "longitude": "-74.217933",
      "label": "New York"
  },
  {
      "value": "OH",
      "zoom":6,
      "latitude": "40.417287",
      "longitude": "-82.907123",
      "label": "Ohio"
  },
  {
      "value": "OK",
      "zoom":5,
      "latitude": "35.007752",
      "longitude": "-97.092877",
      "label": "Oklahoma"
  },
  {
      "value": "OR",
      "zoom":5,
      "latitude": "43.804133",
      "longitude": "-120.554201",
      "label": "Oregon"
  },
  {
      "value": "PA",
      "zoom":5,
      "latitude": "41.203322",
      "longitude": "-77.194525",
      "label": "Pennsylvania"
  },
  {
      "value": "PR",
      "zoom":6,
      "latitude": "18.220833",
      "longitude": "-66.590149",
      "label": "Puerto Rico"
  },
  {
      "value": "RI",
      "zoom":8,
      "latitude": "41.580095",
      "longitude": "-71.477429",
      "label": "Rhode Island"
  },
  {
      "value": "SC",
      "zoom":6,
      "latitude": "33.836081",
      "longitude": "-81.163725",
      "label": "South Carolina"
  },
  {
      "value": "SD",
      "zoom":5,
      "latitude": "43.969515",
      "longitude": "-99.901813",
      "label": "South Dakota"
  },
  {
      "value": "TN",
      "zoom":5,
      "latitude": "35.517491",
      "longitude": "-86.580447",
      "label": "Tennessee"
  },
  {
      "value": "TX",
      "zoom":5,
      "latitude": "31.968599",
      "longitude": "-99.901813",
      "label": "Texas"
  },
  {
      "value": "UT",
      "zoom":5,
      "latitude": "39.32098",
      "longitude": "-111.093731",
      "label": "Utah"
  },
  {
      "value": "VA",
      "zoom":6,
      "latitude": "37.431573",
      "longitude": "-78.656894",
      "label": "Virginia"
  },
  {
      "value": "VT",
      "zoom":6,
      "latitude": "44.558803",
      "longitude": "-72.577841",
      "label": "Vermont"
  },
  {
      "value": "WA",
      "zoom":5,
      "latitude": "47.751074",
      "longitude": "-120.740139",
      "label": "Washington"
  },
  {
      "value": "WI",
      "zoom":5,
      "latitude": "43.78444",
      "longitude": "-88.787868",
      "label": "Wisconsin"
  },
  {
      "value": "WV",
      "zoom":6,
      "latitude": "38.597626",
      "longitude": "-80.454903",
      "label": "West Virginia"
  },
  {
      "value": "WY",
      "zoom":5,
      "latitude": "43.075968",
      "longitude": "-107.290284",
      "label": "Wyoming"
  }
];

const StatesSelect = ({ id, control, isError }) => {
    return (
        <Controller
            name={ id }
            control={ control }
            render={ ({ field }) => (
                <Select
                    { ...field }
                    isClearable
                    menuPortalTarget={ document.body }
                    id={ id }
                    options={ NewStateLists }
                    styles={ {
                        control: (styles, { isFocused }) => ({
                            ...styles,
                            borderWidth: '1px',
                            minHeight: '46.5px',
                            borderRadius: '8px',
                            backgroundColor: 'var(--card)',
                            border: `${
                                isError
                                    ? '1px solid #e33434'
                                    : isFocused
                                        ? '1px solid #3A63D3'
                                        : '1px solid #F4F4F4'
                            }`,
                            boxShadow: '0 !important',
                            '&:hover': {
                                border: `${
                                    isError
                                        ? '1px solid #e33434'
                                        : isFocused
                                            ? '1px solid #3A63D3'
                                            : '1px solid #F4F4F4'
                                }`,
                            },
                        }),
                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                        menu: styles => ({
                            ...styles,
                            background: 'var(--card)',
                            borderColor: 'var(--text-color)',
                        }),
                        option: (styles, { isFocused, isSelected }) => ({
                            ...styles,
                            backgroundColor: (isFocused || isSelected) ? 'var(--blue)' : 'var(--card)',
                            borderColor: 'var(--text-color)',
                            color: (isFocused || isSelected) ? 'white' : 'var(--text-color)',
                            cursor: 'pointer',
                            '&:hover': {
                                backgroundColor: 'var(--blue)',
                                color: 'white'
                            },
                        }),
                        singleValue: styles => ({
                            ...styles,
                            color: 'var(--text-color)',
                        }),
                        input: styles => ({
                            ...styles,
                            paddingTop: '0.25rem',
                            paddingBottom: '0.25rem',
                            color: 'var(--text-color)',
                        }),
                    } }
                />
            ) }
        />
    );
};

export default StatesSelect;
