export const menus = [
	{
		url: '/',
		name: 'Dashboard',
	},
	{
		url: '/rental-listings',
		name: 'Rental Listings',
	},
	{
		url: '/our-mission',
		name: 'Our Mission',
	},
	{
		url: '/contact',
		name: 'Contact',
	},
	// {
	//   url: '/marketplace',
	//   name: 'Menu',
	// },
	// {
	//   url: '/profile',
	//   name: 'Profile',
	// },
	//   url: '/blog',
	//   name: 'Blog',
	// },
];
