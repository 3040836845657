import React from 'react';

import './WelcomePopup.css';

const WelcomePopup = ({ onClose }) => {
	return (
		<div className='welcome-popup-wrapper'>
			<div className='welcome-popup'>
				<h2 className='tw-text-black'>👋 Welcome to RentDaddy</h2>
				<p className='tw-text-gray-400'>
          Where we strive to make the Real Estate Rental market{ ' ' }
					<b>transparent</b> with your help. Join the movement!
				</p>
				<button
					className='btn add-rental-btn'
					onClick={ onClose }>
          Let&apos;s Get Started
				</button>
				<button
					className='close-btn'
					onClick={ onClose }>
          ×
				</button>
			</div>
		</div>
	);
};

export default WelcomePopup;
