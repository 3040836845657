import React from 'react';
import {Layouts} from 'src/components';

const TermsAndConditions = () => {
  return (
    <Layouts>
      <div className=" tw-shadow-md tw-rounded-md tw-my-8 tw-p-4">
        <h1 className="tw-text-center ">Terms and Conditions</h1>
        <p className="tw-pt-3 ">Last Updated: June 1, 2023</p>

        <p className="tw-pt-3 ">
          These Terms and Conditions (&apos;Terms&apos;, &apos;Agreement&apos;)
          govern your use of the RentDaddy LLC (&apos;RentDaddy,&apos;
          &apos;we,&apos; &apos;us,&apos; or &apos;our&apos;) website, mobile
          application, and any related services (collectively referred to as the
          &apos;Service&apos;). Please read these Terms carefully before using
          our Service.
        </p>

        <p className="tw-pt-3 ">
          Acceptance of Terms: By accessing and using our Service, you accept
          and agree to be bound by the terms and provisions of this agreement.
          If you do not agree with these Terms, please do not access or use our
          Service.
        </p>

        <p className="tw-pt-3 ">
          Registration: Some areas of the Service may require registration or
          may otherwise ask you to provide information to participate in certain
          features or to access certain content. Your decision to provide this
          information is purely optional; however, if you elect not to provide
          such information, you may not be able to access certain content or
          participate in certain features of the Service.
        </p>
        <p className="tw-pt-3 ">
          User Conduct: You agree to use the Service only for lawful purposes.
          You agree not to take any action that might compromise the security of
          the Service, render the Service inaccessible to others, or otherwise
          cause damage to the Service or its content.
        </p>
        <p className="tw-pt-3 ">
          Intellectual Property Rights: All content, features, and functionality
          on the Service, including text, graphics, logos, icons, images, and
          software, are the exclusive property of RentDaddy and are protected by
          international copyright, trademark, patent, trade secret, and other
          intellectual property or proprietary rights laws.
        </p>
        <p className="tw-pt-3 ">
          Privacy: Your use of the Service is subject to our Privacy Policy.
          Please review the policy for more on how we collect and use
          information.
        </p>
        <p className="tw-pt-3 ">
          Limitation of Liability: In no event will RentDaddy be liable for any
          direct, indirect, special, punitive, exemplary or consequential losses
          or damages of whatsoever kind arising out of your use or access to the
          Service.
        </p>

        <p className="tw-pt-3 ">
          Modification: We reserve the right to modify these Terms at any time.
          We will notify you of any changes to these Terms by posting new Terms
          on the Service. Your continued use of the Service after any such
          changes constitutes your acceptance of the new Terms.
        </p>

        <p className="tw-pt-3 ">
          Governing Law: These Terms shall be governed by and interpreted in
          accordance with the laws of [Your Company’s Jurisdiction], and you
          consent to the exclusive jurisdiction of the state and federal courts
          located in [Your Company’s Jurisdiction].
        </p>

        <p className="tw-pt-3 ">
          If you have any questions or concerns about this Disclaimer, please
          contact us at{' '}
          <a href="mailto:rentdaddyinfo@gmail.com">rentdaddyinfo@gmail.com</a>
        </p>
      </div>
    </Layouts>
  );
};

export default TermsAndConditions;
