import {Fragment, useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {NumericFormat} from 'react-number-format';
import {Dialog, Transition} from '@headlessui/react';

import RentalsService from 'src/services/rentals';
import {STATES_OPTIONS} from './StatesSelect';
import MySelect from './MySelect';

const FilterModal = ({isOpen, setIsOpen, onFilter, onReset}) => {
  const [configsItems, setConfigsItems] = useState();

  function closeModal() {
    setIsOpen(false);
  }

  const {register, handleSubmit, reset, control} = useForm();

  useEffect(() => {
    const getConfigs = async () => {
      // setLoading(true);
      try {
        const configs = await RentalsService.getConfigs();
        setConfigsItems(configs);
      } catch (error) {
        toast.error(error);
      }
      // setLoading(false);
    };

    getConfigs();
  }, []);

  const onSubmit = data => {
    const filterData = {
      price_min: data.price_min,
      price_max: data.price_max,
      beds: data?.beds?.map(e => e.value) ?? [],
      property_type: data?.property_type?.map(e => e.value) ?? [],
      state: data?.state?.value ?? null,
    };
    onFilter && onFilter(filterData);
  };

  const handleReset = () => {
    reset({
      price_min: '',
      price_max: '',
      beds: null,
      property_type: null,
    });
    onReset && onReset();
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="tw-relative tw-z-[100]"
          onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="tw-ease-out tw-duration-300"
            enterFrom="tw-opacity-0"
            enterTo="tw-opacity-100"
            leave="tw-ease-in tw-duration-200"
            leaveFrom="tw-opacity-100"
            leaveTo="tw-opacity-0">
            <div className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-25" />
          </Transition.Child>

          <div className="tw-fixed tw-inset-0 tw-overflow-y-auto">
            <div className="tw-flex tw-min-h-full tw-items-center tw-justify-center tw-p-4 tw-text-center">
              <Transition.Child
                as={Fragment}
                enter="tw-ease-out tw-duration-300"
                enterFrom="tw-opacity-0 tw-scale-95"
                enterTo="tw-opacity-100 tw-scale-100"
                leave="tw-ease-in tw-duration-200"
                leaveFrom="tw-opacity-100 tw-scale-100"
                leaveTo="tw-opacity-0 tw-scale-95">
                <Dialog.Panel
                  id="modal"
                  className="tw-w-full tw-max-w-md tw-relative tw-transform tw-rounded-2xl card tw-p-6 tw-text-left tw-align-middle tw-shadow-xl tw-transition-all">
                  <Dialog.Title
                    as="h3"
                    className="tw-border-solid tw-border-0 tw-border-b tw-border-gray-300 tw-pb-2">
                    <p className="tw-text-[var(--text-color)]">
                      Filter data points
                    </p>
                  </Dialog.Title>
                  <button
                    onClick={() => closeModal()}
                    className="tw-absolute -tw-top-3 -tw-right-3 tw-rounded-full tw-bg-red-500 tw-text-white tw-border-0 tw-cursor-pointer tw-w-8 tw-h-8">
                    X
                  </button>
                  <div className="tw-mt-2 tw-flex tw-flex-col tw-gap-2">
                    <div className="tw-flex max-sm:tw-flex-col tw-gap-2 tw-items-center">
                      <div className="form-input max-sm:tw-w-full tw-mt-2">
                        <label htmlFor="min"> Price Min </label>
                        <Controller
                          name="price_min"
                          control={control}
                          render={({field}) => (
                            <NumericFormat
                              className="tw-w-full"
                              {...field}
                              placeholder="$"
                              thousandSeparator={true}
                              prefix={'$'}
                            />
                          )}
                        />
                      </div>
                      <span className="tw-pt-8 max-sm:tw-hidden">-</span>
                      <div className="form-input max-sm:tw-w-full tw-mt-2">
                        <label htmlFor="max"> Price Max </label>
                        <Controller
                          name="price_max"
                          control={control}
                          render={({field}) => (
                            <NumericFormat
                              {...field}
                              className="tw-w-full"
                              placeholder="$"
                              thousandSeparator={true}
                              prefix={'$'}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="form-input">
                      <label htmlFor="beds"> Beds </label>
                      <MySelect
                        id="beds"
                        isMulti
                        control={control}
                        {...register('beds')}
                        options={configsItems?.numberBeds?.map(e => {
                          return {
                            label: e,
                            value: e,
                          };
                        })}
                      />
                    </div>
                    <div className="form-input">
                      <label htmlFor="property_type"> Property Type </label>
                      <MySelect
                        id="property_type"
                        control={control}
                        isMulti
                        {...register('property_type')}
                        options={configsItems?.propertyType?.map(e => {
                          return {
                            label: e,
                            value: e,
                          };
                        })}
                      />
                    </div>
                    <div className="form-input">
                      <label htmlFor="state"> State </label>
                      <MySelect
                        id="state"
                        control={control}
                        {...register('state')}
                        options={STATES_OPTIONS}
                      />
                    </div>
                  </div>
                  <div className="tw-mt-4 tw-flex tw-items-center tw-justify-between">
                    <button
                      onClick={() => handleReset()}
                      className="btn btn-danger">
                      Reset
                    </button>
                    <button onClick={handleSubmit(onSubmit)} className="btn">
                      Apply Filter
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default FilterModal;
