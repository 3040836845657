import React, {useEffect, useRef, useState} from 'react';
import {BsFilterCircleFill, BsPlus} from 'react-icons/bs';
import Map, {Marker, Popup} from 'react-map-gl';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import {_} from 'gridjs-react';
import moment from 'moment';
import {useLocalStorage, useReadLocalStorage} from 'usehooks-ts';
import {analytics} from '../../config/firebase';
import {logEvent} from 'firebase/analytics';

import {Layouts, Table, WelcomePopup} from 'src/components';
import CitySelect from 'src/components/CitySelect';
import FilterModal from 'src/components/FilterModal';
import {useGetFirestoreWithPagination} from 'src/hooks/useGetFirestoreWithPagination';
import RentalsService from 'src/services/rentals';

import 'mapbox-gl/dist/mapbox-gl.css';
import 'gridjs/dist/theme/mermaid.css';
import '../../styles/home.css';
import DashboardMission from './DashboardMission';
import DashboardContact from './DashboardContact';
import {NewStateLists} from 'src/components/StatesSelect';
import HeroSection from './HeroSection';
// import ScrollComponent from './ScrollComponent';

const Home = () => {
  const {allData, markerColors, fetchDataWithFilter, fetchAllData} =
    useGetFirestoreWithPagination('rentals');

  const mapRef = useRef();
  const [popupInfo, setPopupInfo] = useState(null);
  const [defaultCities, setDefaultCities] = useState([]);
  const darkMode = useReadLocalStorage('darkTheme');
  const [isSatelite, setIsSatelite] = useLocalStorage('isSatelite', false);
  const [legendColors, setLegendColors] = useState([]);
  const [showWelcomePopup, setShowWelcomePopup] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);

  const scrollRef = useRef(null);

  const scrollToSection = () => {
    scrollRef.current.scrollIntoView({behavior: 'smooth'});
  };

  const handleStyleLoad = map => {
    mapRef.current = map.target;
  };

  useEffect(() => {
    if (!sessionStorage.getItem('dashboardVisited')) {
      toast.info('Welcome back to RentDaddy!');
      sessionStorage.setItem('dashboardVisited', 'true');
    }
  }, []);

  useEffect(() => {
    const getDefaultCities = async () => {
      try {
        const resDefaultCities = await RentalsService.getDefaultCities();
        setDefaultCities(resDefaultCities);
      } catch (error) {
        toast.error(error);
      }
    };

    getDefaultCities();
  }, []);

  useEffect(() => {
    const fetchMarkerColors = async () => {
      try {
        const data = await RentalsService.getMarkerColors();

        setLegendColors(data);
      } catch (error) {
        // console.error('Error fetching marker colors:', error);
      }
    };
    fetchMarkerColors();
  }, []);

  useEffect(() => {
    if (!localStorage.getItem('welcomePopupShown')) {
      setShowWelcomePopup(true);
      localStorage.setItem('welcomePopupShown', 'true');
    }
  }, []);

  useEffect(() => {}, [legendColors]);

  const getMarkerColor = e => {
    let color;
    let units = 0;
    for (let i = 0; i < allData.length; i++) {
      if (
        allData[i].coordinates[0] === e?.coordinates[0] &&
        allData[i].coordinates[1] === e?.coordinates[1]
      ) {
        units += 1;
      }
    }

    if (units > 1) {
      color = '#4169e1';
      return color;
    }

    let price = e.price;
    let markerIndex = 0;
    const markerFilter = markerColors.min.filter(item => {
      return item <= Number(price.replace(/[^0-9.-]+/g, ''));
    });
    markerFilter.forEach(e => {
      if (e <= Number(price.replace(/[^0-9.-]+/g, ''))) markerIndex = e;
    });
    color =
      markerColors.color[
        markerColors.min.findIndex(item => item === markerIndex)
      ];

    // Change the yellow color to a darker shade
    if (color === 'yellow') {
      color = '#ffdf00'; // Replace this hex code with your preferred darker yellow shade
    }

    return color;
  };

  const handleFilter = e => {
    if (e?.value) {
      console.log(`🚀 ~ file: index.jsx:100 ~ handleFilter ~ e:`, e);
      mapRef.current.flyTo({
        bearing: 0,
        center: e.value,
        zoom: 8,
      });
    }
  };

  const getDataFilter = async data => {
    fetchDataWithFilter(data);

    let SpecificStateArray = NewStateLists.filter(item => {
      return item.value == data.state ? item : null;
    });

    mapRef.current.flyTo({
      essential: true,
      bearing: 0,
      center: [
        Number(SpecificStateArray[0].longitude),
        Number(SpecificStateArray[0].latitude),
      ],
      zoom: SpecificStateArray[0].zoom,
    });
    setShowFilterModal(false);
  };

  return (
    <Layouts>
      <HeroSection scrollToSection={scrollToSection} />
      <div className="container home" ref={scrollRef}>
        <div style={{ marginBottom: "16px" }} className="card tw-shadow-md">
          <div className="home__search ">
            <div className="tw-flex max-sm:tw-flex-col max-md:tw-w-full tw-items-center tw-gap-2">
              <div className="input">
                <CitySelect
                  defaultOptions={defaultCities}
                  onSelected={(e) => handleFilter(e)}
                />
              </div>
              <button
                onClick={() => {
                  setShowFilterModal(true);
                  logEvent(analytics, "map_filter_open");
                }}
                className="btn tw-bg-[#FA8072] !tw-mt-0"
              >
                <BsFilterCircleFill />
                <span>Filter by price, beds, etc.</span>
              </button>
            </div>
            <Link
              to="/add-rental"
              className="btn"
              onClick={() => logEvent(analytics, "add_rental_clicked")}
            >
              <BsPlus />
              <span>Add Your Rental</span>
            </Link>
          </div>
        </div>
        <div className="card tw-shadow-md">
          <div className="home__content">
            <div className="home__content__maps">
              <Map
                mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
                initialViewState={{
                  longitude: -96.5795, // Center of the USA
                  latitude: 39.8283,
                  zoom: 1.5,
                }}
                onLoad={handleStyleLoad}
                mapStyle={
                  isSatelite
                    ? "mapbox://styles/mapbox/satellite-streets-v12"
                    : darkMode
                    ? "mapbox://styles/mapbox/dark-v11"
                    : "mapbox://styles/mapbox/streets-v12"
                }
              >
                {allData
                  ?.filter((e) => e?.coordinates)
                  .map((e) => {
                    return (
                      <Marker
                        key={e.id}
                        longitude={e.coordinates[0]}
                        latitude={e.coordinates[1]}
                        style={{
                          cursor: "pointer",
                        }}
                        color={getMarkerColor(e)}
                        onClick={(event) => {
                          event.originalEvent.stopPropagation();
                          setPopupInfo(e);
                        }}
                      />
                    );
                  })}
                {popupInfo && (
                  <Popup
                    anchor="top"
                    className="!tw-p-0"
                    longitude={Number(popupInfo.coordinates[0])}
                    latitude={Number(popupInfo.coordinates[1])}
                    onClose={() => setPopupInfo(null)}
                  >
                    <div className="tw-flex tw-flex-col tw-min-w-[90px] tw-max-w-[120px] tw-divide-y tw-divide-x-0 tw-divide-gray-500 tw-divide-solid tw-text-black">
                      {allData?.filter(
                        (e) =>
                          e?.coordinates[0] === popupInfo.coordinates[0] &&
                          e?.coordinates[1] === popupInfo.coordinates[1]
                      ).length > 1 && (
                        <h2 className="tw-pt-3 tw-pb-2">
                          {
                            allData?.filter(
                              (e) =>
                                e?.coordinates[0] ===
                                  popupInfo.coordinates[0] &&
                                e?.coordinates[1] === popupInfo.coordinates[1]
                            ).length
                          }{" "}
                          Units
                        </h2>
                      )}
                      {allData
                        ?.filter(
                          (e) =>
                            e?.coordinates[0] === popupInfo.coordinates[0] &&
                            e?.coordinates[1] === popupInfo.coordinates[1]
                        )
                        .sort((a, b) => {
                          const code = (x) =>
                            Number(x.replace(/[^0-9.-]+/g, ""));
                          return code(a.price) - code(b.price);
                        })
                        .map((pop, popIdx) => {
                          console.log(
                            `🚀 ~ file: index.jsx:264 ~ .map ~ pop:`,
                            pop
                          );

                          return (
                            <div
                              key={popIdx}
                              className={`tw-py-2 tw-px-1 ${
                                popIdx === 0 && "tw-my-auto tw-mx-0"
                              }`}
                            >
                              <div className="flex tw-justify-between tw-items-center">
                                <span>
                                  <b>Rent:</b> {pop.price}{" "}
                                </span>
                                {allData?.filter(
                                  (e) =>
                                    e?.coordinates[0] ===
                                      popupInfo.coordinates[0] &&
                                    e?.coordinates[1] ===
                                      popupInfo.coordinates[1]
                                ).length > 1 ? (
                                  <div
                                    className={`span_bullet ${
                                      Number(pop.price.replace(/[$,]/g, "")) <
                                      legendColors.min[1]
                                        ? `color-${legendColors.color[0]}`
                                        : Number(
                                            pop.price.replace(/[$,]/g, "")
                                          ) < legendColors.min[2]
                                        ? `color-${legendColors.color[1]}`
                                        : `color-${legendColors.color[2]}`
                                    }`}
                                  ></div>
                                ) : (
                                  ""
                                )}
                              </div>
                              {pop.beds} bd | {pop.baths} ba
                            </div>
                          );
                        })}
                    </div>
                  </Popup>
                )}
              </Map>
              <div className="home__content__maps__satelite tw-cursor-pointer">
                <div className="flex items-center mb-4">
                  <input
                    id="satelite-checkbox"
                    type="checkbox"
                    checked={isSatelite}
                    onChange={(e) => {
                      setIsSatelite(e.target.checked);
                      if (e.target.checked) {
                        logEvent(analytics, "satellite_on");
                      } else {
                        logEvent(analytics, "satellite_off");
                      }
                    }}
                  />
                  <label
                    htmlFor="satelite-checkbox"
                    className="ml-2 text-sm font-medium tw-cursor-pointer text-gray-400 dark:text-gray-500"
                  >
                    Satellite mode
                  </label>
                </div>
              </div>
              <div className="home__content__maps__legend">
                <h5>Legend</h5>
                <ul>
                  {legendColors.color &&
                    legendColors.min &&
                    legendColors.color.map((color, index) => (
                      <li key={index}>
                        <div className={`bullet ${color}`} />
                        {index < legendColors.min.length - 1
                          ? `$${new Intl.NumberFormat().format(
                              legendColors.min[index]
                            )} - $${new Intl.NumberFormat().format(
                              legendColors.min[index + 1] - 1
                            )}`
                          : `$${new Intl.NumberFormat().format(
                              legendColors.min[index]
                            )}+`}
                      </li>
                    ))}

                  <li>
                    <div className="bullet tw-bg-[#4169e1]"/>Multiple
                  </li>
                </ul>
              </div>
            </div>
            <div className="home__content__table">
              <h4 className="home__content__table__title">Rental Data Table</h4>
              <Table
                mapRef={mapRef}
                data={allData}
                columns={[
                  {
                    name: "coordinates",
                    id: "coordinates",
                    hidden: true,
                  },
                  {
                    name: "Price",
                    id: "price",
                    sort: {
                      compare: (a, b) => {
                        const code = (x) => Number(x.replace(/[^0-9.-]+/g, ""));
                        return code(a) - code(b);
                      },
                    },
                  },
                  {
                    name: "Beds",
                    id: "beds",
                    sort: {
                      compare: (a, b) => {
                        const code = (x) => x.replace(/[^0-9.-]+/g, "");
                        return code(a) - code(b);
                      },
                    },
                  },
                  {
                    name: "Baths",
                    id: "baths",
                    sort: {
                      compare: (a, b) => {
                        const code = (x) => Number(x);
                        return code(a) - code(b);
                      },
                    },
                  },
                  {
                    name: "Type",
                    id: "propertyType",
                    sort: true,
                  },
                  {
                    name: "Start",
                    id: "moveInDate",
                    sort: {
                      compare: (a, b) => {
                        return new Date(b) - new Date(a);
                      },
                    },
                    formatter: (cell) => {
                      return _(<div>{moment(cell).format("MM/YYYY")}</div>);
                    },
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <DashboardMission />

        <div className="card tw-py-[24px] tw-px-[20px] tw-rounded-[10px] tw-mt-4 tw-shadow-md tw-h-auto">
          <p className="tw-text-3xl tw-font-bold">
            Analytics - title, below subtitle
          </p>
          <p className="">This is the section for analytics, graphs, etc...</p>
          <div className="tw-grid tw-gap-8 md:tw-grid-cols-1 md:tw-grid-rows-4 lg:tw-grid-cols-2 lg:tw-grid-rows-2 tw-pt-8 tw-pb-8 lg:tw-p-8">
            <div className="tw-flex tw-justify-center">
              <img
                src="1.png"
                alt="1st"
                className="sm:tw-w-[80%] tw-w-[100%] md:tw-h-[300px] md:tw-w-auto lg:tw-h-[300px] lg:tw-w-auto tw-h-auto tw-shadow-md tw-rounded-[10px]"
              />
            </div>
            <div className="tw-flex tw-justify-center">
              <img
                src="2.png"
                alt="2nd"
                className="sm:tw-w-[80%] tw-w-[100%] md:tw-h-[300px] md:tw-w-auto lg:tw-h-[300px] lg:tw-w-auto tw-h-auto tw-shadow-md tw-rounded-[10px]"
              />
            </div>
            <div className="tw-flex tw-justify-center">
              <img
                src="3.png"
                alt="3rd"
                className="sm:tw-w-[80%] tw-w-[100%] md:tw-h-[300px] md:tw-w-auto lg:tw-h-[300px] lg:tw-w-auto tw-h-auto tw-shadow-md tw-rounded-[10px]"
              />
            </div>
            <div className="tw-flex tw-justify-center">
              <img
                src="4.png"
                alt="4th"
                className="sm:tw-w-[80%] tw-w-[100%] md:tw-h-[300px] md:tw-w-auto lg:tw-h-[300px] lg:tw-w-auto tw-h-auto tw-shadow-md tw-rounded-[10px]"
              />
            </div>
          </div>

          {/* <div className="tw-grid tw-gap-8 md:tw-grid-cols-2 md:tw-grid-rows-2 tw-h-[90%] tw-pt-8 tw-pb-8">
          <img
                src="1.png"
                alt="1st"
                className="md:tw-w-auto tw-h-[300px] tw-shadow-md"
              />
              <img
                src="2.png"
                alt="2nd"
                className="md:tw-w-auto tw-h-[300px] tw-shadow-md"
              />
            <img
                src="3.png"
                alt="3rd"
                className="md:tw-w-auto tw-h-[300px] tw-shadow-md"
              />
            <img
                src="4.png"
                alt="4th"
                className="md:tw-w-auto tw-h-[300px] tw-shadow-md"
              />
          </div> */}
        </div>

        <DashboardContact />
      </div>
      {showWelcomePopup && (
        <WelcomePopup
          onClose={() => {
            setShowWelcomePopup(false);
          }}
        />
      )}
      <FilterModal
        isOpen={showFilterModal}
        onReset={() => {
          fetchAllData();
          setShowFilterModal(false);
        }}
        setIsOpen={(e) => setShowFilterModal(e)}
        onFilter={(data) => getDataFilter(data)}
      />
    </Layouts>
  );
};

export default Home;
