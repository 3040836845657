import * as yup from 'yup';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const AddRentalsValidationSchema = yup.object().shape({
	address: yup.string().required()
		.label('Address'),
	city: yup.string().required()
		.label('City'),
	state: yup.string().required()
		.label('State'),
	zip_code: yup.string().required()
		.label('Zip Code'),
	move_in: yup.string().required()
		.label('Move In'),
	price: yup.string().required()
		.label('Price'),
	move_out: yup.string().label('Move Out'),
	property_type: yup
		.object()
		.shape({
			label: yup.string().required(),
			value: yup.string().required('Property Type is a required field'),
		})
		.nullable()
		.required()
		.label('Property Type'),
	beds: yup
		.object()
		.shape({
			label: yup.string().required(),
			value: yup.string().required('Beds is a required field'),
		})
		.nullable()
		.required()
		.label('Beds'),
	baths: yup
		.object()
		.shape({
			label: yup.string().required(),
			value: yup.string().required('Baths is a required field'),
		})
		.nullable()
		.required()
		.label('Baths'),
	include_utilites: yup
		.array()
		.of(
			yup.object().shape({
				label: yup.string().required(),
				value: yup.string().required('Include Utilites is a required field'),
			}),
		)
		.nullable()
		.min(1, 'Include Utilites is a required field')
		.required()
		.label('Include Utilites'),
	comments: yup.string().label('Comments'),
	coordinates: yup
		.array()
		.min(2, 'Coordinates is a required field')
		.label('Coordinates'),
	name: yup.string().label('Name'),
	email: yup.string().email()
		.label('Email'),
	phoneNumber: yup
		.string()
		.matches(phoneRegExp, {
			message: 'Phone number is not valid',
			excludeEmptyString: true,
		})
		.label('Phone Number'),
});
