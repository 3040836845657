import React from 'react';
import {Layouts} from 'src/components';

const PrivacyPolicy = () => {
  return (
    <Layouts>
      <div className=" tw-shadow-md tw-rounded-md tw-my-8 tw-p-4">
        <p className="tw-text-3xl tw-font-bold tw-text-center">Privacy Policy</p>
        <p className="tw-pt-3 ">Last Updated: June 1, 2023</p>

        <p className="tw-pt-3 ">
          This Privacy Policy governs the manner in which RentDaddy LLC
          (&apos;RentDaddy,&apos; &apos;we,&apos; &apos;us,&apos; or
          &apos;our&apos;) collects, uses, maintains, and discloses information
          collected from users (&apos;Users&apos; or &apos;you&apos;) of the
          RentDaddy website, mobile application, and any related services
          (collectively referred to as the &apos;Service&apos;).
        </p>

        <p className="tw-pt-3 ">
          1. Information We Collect 1.1 Personal Information: Wemaycollect
          personally identifiable information from Users in various ways,
          including but not limited to when Users visit our website, register an
          account, place an order, fill out a form, respond to a survey, or
          interact with our Service. The types of personal information we may
          collect include, but are not limited to, the following:
          <ul className="customUnorderList">
            <li>- Name</li>
            <li>- Email address</li>
            <li>- Phone number</li>
            <li>- Payment information</li>
            <li>- Date of birth</li>
            <li>- Social media account information (if provided)</li>
          </ul>
        </p>

        <p className="tw-pt-3 ">
          1.2 Non-Personal Information: Wemayalso collect non-personal
          information about Users whenever they interact with our Service.
          Non-personal information may include the browser name, the type of
          computer or device used, technical information about Users&apos; means
          of connection to our Service (such as the operating system, Internet
          service provider utilized, and other similar information), and other
          similar information.
        </p>
        <p className="tw-pt-3 ">
          2. How WeUse Collected Information
        </p>
        <p className="tw-pt-3 ">
          2.1 Personal Information: Wemayuse the personal information provided
          by Users for the following purposes:
          <ul className="customUnorderList">
            <li>- To personalize the User experience</li>
            <li>
              - To process transactions and deliver the requested products or
              services
            </li>
            <li>- To improve customer service and respond to inquiries</li>
            <li>
              - To send periodic emails or notifications regarding updates,
              promotions, and related information
            </li>
            <li>
              - To administer surveys, contests, or other Service features
            </li>
          </ul>
        </p>
        <p className="tw-pt-3 ">
          2.2 Non-Personal Information: Wemayuse non-personal information for
          analytical and statistical purposes, to improve our Service, and for
          other business purposes.
        </p>
        <p className="tw-pt-3 ">
          3. How WeProtect Your Information Weadopt appropriate data collection,
          storage, and processing practices, as well as security measures, to
          protect against unauthorized access, alteration, disclosure, or
          destruction of your personal information, username, password,
          transaction information, and data stored on our Service.
        </p>

        <p className="tw-pt-3 ">
          4. Sharing Your Personal Information Wedonot sell, trade, or rent
          Users&apos; personal identification information to others. We may
          share generic aggregated demographic information not linked to any
          personal identification information regarding visitors and Users with
          our business partners, trusted affiliates, and advertisers for the
          purposes outlined above.
        </p>

        <p className="tw-pt-3 ">
          5. Third-Party Websites and Services Users may find advertising or
          other content on our Service that link to the websites and services of
          our partners, suppliers, advertisers, sponsors, licensors, and other
          third parties. We do not control the content or links that appear on
          these websites and are not responsible for the practices employed by
          websites linked to or from our Service. Browsing and interaction on
          any other website, including websites that have a link to our Service,
          is subject to that website&apos;s own terms and policies.
        </p>

        <p className="tw-pt-3 ">
          6. Changes to This Privacy Policy Rent Daddy has the discretion to
          update this Privacy Policy at any time. When we do, we will revise the
          updated date at the top of this page. We encourage Users to frequently
          check this page for any changes to stay informed about how we are
          helping to protect the personal information we collect. You
          acknowledge and agree that it is your responsibility to review this
          Privacy Policy periodically and become aware of modifications.
        </p>

        <p className="tw-pt-3 ">
          7. Your Acceptance of These Terms By using our Service, you signify
          your acceptance of this Privacy Policy. If you do not agree to this
          Privacy Policy, please do not use our Service. Your continued use of
          the Service following the posting of changes to this Privacy Policy
          will be deemed as your acceptance of those changes.
        </p>

        <p className="tw-pt-3 ">
          8. Contact Us If you have any questions about this Privacy Policy, the
          practices of our Service, or your dealings with us, please contact us
          at{' '}
          <a href="mailto:rentdaddyinfo@gmail.com">rentdaddyinfo@gmail.com</a>
        </p>
      </div>
    </Layouts>
  );
};

export default PrivacyPolicy;
