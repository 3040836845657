import React from 'react';

const DashboardMission = () => {
  return (
    <div className="card our-mission tw-py-[24px] tw-px-[20px] tw-rounded-[10px] tw-mt-4 tw-h-auto tw-shadow-md">
      <h1>Our Mission</h1>
      <p className="mt-24 ">
        Welcome to Rent Daddy - a platform dedicated to helping renters find the
        best deals and fair prices in the real estate rental market.
        <br />
        <br />
        We understand that finding affordable and fair rental prices can be a
        challenging task, especially in today&apos;s market. That&apos;s why our
        mission is to empower renters with the information and data they need to
        make informed decisions about their housing options.
        <br />
        <br />
        Our goal is to collect and analyze real estate rental market numbers
        from across the country to provide renters with the most accurate and
        up-to-date information about the market.{' '}
        <b>
          By doing so, we hope to create a more transparent rental market where
          renters can find the best deals and negotiate fair prices with
          landlords.
        </b>
        <br />
        <br />
        At Rent Daddy, we believe that everyone deserves a comfortable and
        affordable place to call home, and we are committed to making that a
        reality for renters everywhere. Join us in our mission to create a fair
        and transparent rental market for all.
        <br />
        <br />
        We are excited for your new beginnings,
        <br />
        <br />- RentDaddy Team
      </p>
    </div>
  );
};

export default DashboardMission;