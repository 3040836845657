import React, {useState} from 'react';

import './StarRating.css';
import {Rating} from '@mui/material';

const StarRating = ({onRatingChange}) => {
  const [rating, setRating] = useState(null);
  const [hover, setHover] = useState(null);

  const handleClick = index => {
    setRating(index);
  onRatingChange && onRatingChange(index);
  };

  return (
    <div className="star-rating">
      <div className="star-container">
        {/* { [...Array(5)].map((star, i) => {
					const ratingValue = i + 1;
					return (
						<button
							type='button'
							key={ i }
							className={ ratingValue <= (hover || rating) ? 'on' : 'off' }
							onClick={ () => handleClick(ratingValue) }
							onMouseEnter={ () => setHover(ratingValue) }
							onMouseLeave={ () => setHover(rating) }
							onDoubleClick={ () => {
								setRating(null);
								setHover(null);
							} }>
							<span className='star'>&#9733;</span>
						</button>
					);
				}) } */}
        <Rating
          name="rating"
          value={rating}
          size="large"
          onChange={(event, newValue) => {
            setRating(newValue);
            onRatingChange(newValue);
          }}
        />
      </div>
      <p className="tw-text-center max-lg:tw-text-sm">
        Overall how happy are you with your place? (optional)
      </p>
    </div>
  );
};

export default StarRating;
