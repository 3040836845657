import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';

import { Layouts } from 'src/components';
import RentalsService from 'src/services/rentals';
import { ContactValidationSchema } from 'src/validations/contact';

import { logEvent } from 'firebase/analytics';
import { analytics } from '../../config/firebase';
import 'src/styles/contact.css';

const Contact = () => {
	const [loading, setLoading] = useState(false);
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(ContactValidationSchema),
	});

	const onSubmit = async data => {
		setLoading(true);
		try {
			const rentalData = {
				name: data.name,
				email: data.email,
				message: data.message,
			};
			await RentalsService.createEmail(rentalData);
			toast.success(
				'We appreciate your feedback. A team member will be in contact with you shortly.',
			);
      logEvent(analytics, 'contact_form_submit');
			reset();
		} catch (error) {
			toast.error(error);
		}
		setLoading(false);
	};
	return (
		<Layouts>
			<div className='container contact tw-shadow-3xl tw-p-8'>
				<div>
					<div className='contact__header'>
						<h1>Together, We Can Achieve More.</h1>
						<h2>Connect With Us 🤝</h2>
						<p>
              We will get back to you within 24-48 hours <b>via email</b>.
						</p>
					</div>
					<form
						className='card grid grid-col-1'
						style={ { marginTop: '24px' } }>
						<div className='form-input'>
							<label htmlFor='name'> Name </label>
							<input
								id='name'
								type='name'
								{ ...register('name') }
								placeholder='Full Name'
							/>
							{ errors.name && (
								<p className='text-error'>{ errors.name.message }</p>
							) }
						</div>
						<div className='form-input'>
							<label htmlFor='email'> Email </label>
							<input
								id='email'
								type='email'
								{ ...register('email') }
								placeholder='Email'
							/>
							{ errors.email && (
								<p className='text-error'>{ errors.email.message }</p>
							) }
						</div>
						<div className='form-input'>
							<label htmlFor='message'> Message</label>
							<textarea
								id='message'
								{ ...register('message') }
								placeholder='Message'
								rows={ 5 }
							/>
							{ errors.message && (
								<p className='text-error'>{ errors.message.message }</p>
							) }
						</div>
						<button
							onClick={ handleSubmit(onSubmit) }
							disabled={ loading }
							style={ { marginLeft: 'auto', marginRight: 'auto', width: '30%' } }
							className='btn'>
              Submit
						</button>
					</form>
				</div>
			</div>
		</Layouts>
	);
};

export default Contact;
