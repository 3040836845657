import React from 'react';
import AsyncSelect from 'react-select/async';
import {logEvent} from 'firebase/analytics';
import {analytics} from '../config/firebase';

function CitySelect({onSelected, defaultOptions}) {
  const loadOptions = async (inputValue, callback) => {
    if (!inputValue) return;
    try {
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${inputValue}.json?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}&types=place&country=us`,
      );
      const results = await response.json();
      const options = results.features.map(feature => ({
        value: feature.center,
        label: feature.place_name,
      }));
      callback(options);
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <AsyncSelect
      cacheOptions
      isClearable
      defaultOptions={defaultOptions}
      onChange={e => {
        onSelected(e);
        if (e && e.label) {
          logEvent(analytics, 'search_your_city', {
            city: e.label,
          });
        }
      }}
      loadOptions={loadOptions}
      placeholder="🔍 Search your city..."
      components={{
        IndicatorSeparator: () => null,
      }}
      styles={{
        container: styles => ({
          ...styles,
          width: '100%',
        }),
        control: styles => ({
          ...styles,
          backgroundColor: 'var(--card)',
        }),
        menu: styles => ({
          ...styles,
          background: 'var(--card)',
        }),
        singleValue: styles => ({
          ...styles,
          color: 'var(--text-color)',
        }),
        option: (styles, {isFocused, isSelected}) => ({
          ...styles,
          backgroundColor:
            isFocused || isSelected ? 'var(--blue)' : 'var(--card)',
          borderColor: 'var(--text-color)',
          color: isFocused || isSelected ? 'white' : 'var(--text-color)',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'var(--blue)',
            color: 'white',
          },
        }),
      }}
    />
  );
}

export default CitySelect;
