import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Layouts } from "src/components";
import RentalsListingService from "src/services/rental-listings";
import { IoBedOutline } from "react-icons/io5";
import { BiBath } from "react-icons/bi";
import { MdSettingsOverscan, MdRoom } from "react-icons/md";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Box, Chip, CircularProgress } from "@mui/material";
import { BiArrowBack } from "react-icons/bi";

const RentalDetails = () => {
  const [Loading, setLoading] = useState(false);
  const [ImageLoading, setImageLoading] = useState(false);
  const [AllImages, setAllImages] = useState([]);
  const [AllNewImages, setAllNewImages] = useState([]);
  const { rental_id } = useParams();
  const [IndivRentalDetails, setIndivRentalDetails] = useState(null);

  useEffect(() => {
    if (rental_id) {
      setLoading(true);
      const getRentalDetails = async () => {
        try {
          const resDetails = await RentalsListingService.getRentalDetails(
            rental_id
          );
          // console.clear();
          console.log(
            `🚀 ~ file: index.jsx:41 ~ getRentalDetails ~ resDetails:`,
            resDetails
          );
          setAllImages(resDetails?.images);
          setIndivRentalDetails(resDetails);
        } catch (error) {
          toast.error(error);
        }

        setLoading(false);
      };

      getRentalDetails();
    }
  }, []);

  useEffect(() => {
    if (AllImages.length > 0) {
      setImageLoading(true);
      let tmp = [];
      AllImages.map((item, index) => {
        tmp.push({ image: item, caption: `${index}` });
      });
      console.log(`🚀 ~ file: rental_details.jsx:56 ~ useEffect ~ tmp:`, tmp);

      setAllNewImages(tmp);
      setImageLoading(false);
    }
  }, [AllImages]);

  return (
    <Layouts fullwidth={true}>
      <div className="rental_cards">
        {Loading ? (
          <div className="tw-h-[80vh] flex tw-justify-center tw-items-center">
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className="sm:tw-grid md:tw-grid-cols-2 auto-cols-max rental_texts md:tw-p-8 md:tw-px-16">
              <div className="tw-p-4">
                <div className="tw-rounded-md">
                  {/* ----------------react-responsive-carousel------------------------ */}

                  {ImageLoading ? (
                    <CircularProgress />
                  ) : (
                    <Carousel
                      // axis={"vertical"}
                      showStatus={false}
                      showIndicators={false}
                      infiniteLoop={true}
                      autoPlay={true}
                      dynamicHeight={false}
                    >
                      {AllImages.map((item, index) => (
                        <div key={index}>
                          <img src={item} alt={`alt_${index}`} />
                        </div>
                      ))}
                    </Carousel>
                  )}

                  {/* ----------------react-responsive-carousel------------------------ */}
                </div>
                <p className="tw-pl-1 tw-text-3xl tw-font-semibold tw-pb-3 tw-text-[#3a63d3]">
                  {IndivRentalDetails?.price}
                  <sup className="tw-text-red-500">*</sup>
                </p>
                <p className=" tw-text-[#3a63d3] flex tw-items-center">
                  <MdRoom />{" "}
                  <p>
                    {IndivRentalDetails?.locationAddress.address},{" "}
                    {IndivRentalDetails?.locationAddress.city},{" "}
                    {IndivRentalDetails?.locationAddress.state},{" "}
                    {IndivRentalDetails?.locationAddress.zipCode}{" "}
                  </p>
                </p>
                <div className="tw-py-1 tw-text-[#3a63d3] flex tw-flex-wrap tw-gap-3 tw-justify-between tw-items-center tw-my-3">
                  <div className="flex tw-items-center tw-gap-2">
                    <IoBedOutline />
                    <p>{IndivRentalDetails?.beds} Bedroom</p>
                  </div>
                  <div className="flex tw-items-center tw-gap-2">
                    <BiBath />
                    <p>{IndivRentalDetails?.bath} Bathroom</p>
                  </div>
                  <div className="flex tw-items-center tw-gap-2">
                    <MdSettingsOverscan />
                    <p>
                      {Number(IndivRentalDetails?.sqft).toLocaleString()} Sq.Ft.
                    </p>
                  </div>
                </div>

                <div className="sm:tw-block tw-hidden">
                  <Link to={"/contact"}>
                    <button className="tw-px-8 tw-py-3 tw-rounded-full tw-border  tw-border-[#3a63d3] tw-text-[#3a63d3]  tw-cursor-pointer tw-bg-transparent">
                      Contact Agent
                    </button>
                  </Link>
                </div>
              </div>
              <div className="tw-p-4 tw-pt-0">
                <div className="tw-py-2">
                  <p className="tw-font-semibold tw-text-[#3a63d3]">
                    Description
                  </p>
                  <p className="rental_texts">
                    {IndivRentalDetails?.description}
                  </p>
                </div>
                <div className="tw-py-2">
                  <p className="tw-font-semibold tw-text-[#3a63d3]">
                    Amenities & Features
                  </p>
                  <div className="tw-flex tw-gap-3 tw-flex-wrap tw-py-3 rental_texts">
                    {IndivRentalDetails?.amenities.map((item, index) => (
                      <Chip label={item} key={index} className="rental_texts" />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="sm:tw-hidden tw-block tw-px-3 tw-py-2 ">
              <Link to={"/contact"}>
                <button className="tw-px-8 tw-py-3 tw-rounded-full tw-border tw-font-mono tw-border-[#3a63d3] tw-text-[#3a63d3] tw-cursor-pointer tw-bg-transparent">
                  Contact Agent
                </button>
              </Link>
            </div>
          </>
        )}
      </div>
    </Layouts>
  );
};

export default RentalDetails;
