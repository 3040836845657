import {
  ButtonGroup,
  CircularProgress,
  FormControl,
  InputLabel,
  NativeSelect,
  Slider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { toast } from "react-toastify";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { IoBedOutline } from "react-icons/io5";
import { BiBath } from "react-icons/bi";
import { MdSettingsOverscan, MdRoom } from "react-icons/md";

import { Layouts } from "src/components";
import RentalsListingService from "src/services/rental-listings";
import { SlArrowDown, SlArrowUp } from "react-icons/sl";

import "src/styles/rentallistings.css";
import { Link } from "react-router-dom";

const RentalListings = () => {
  const [AllInitialRental, setAllInitialRental] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [PriceSliderLabelText, setPriceSliderLabelText] = useState(
    "$0" + " - " + "$10,000"
  );
  const [AllRental, setAllRental] = useState([]);
  const [price, setprice] = React.useState([0, 10000]);
  const [ShowFilters, setShowFilters] = useState(false);
  // const [bedroomFilters, setbedroomFilters] = useState("");
  const [SelectedCity, setSelectedCity] = useState("all");
  const [SelectedPriceSort, setSelectedPriceSort] = useState("all");
  const [SelectedBedRoom, setSelectedBedRoom] = useState("all");

  // FilterBy
  const SortByPriceRange = (event, newValue) => {
    let tmpArray =
      newValue[0] == 0 || newValue[1] == 10000
        ? AllInitialRental
        : AllRental.length > 1
        ? AllRental
        : AllInitialRental;

    setPriceSliderLabelText(
      "$" +
        Number(newValue[0]).toLocaleString() +
        " - " +
        "$" +
        Number(newValue[1]).toLocaleString()
    );

    setprice(newValue);

    let tmp = tmpArray.filter((item) => {
      let price = Number(item.price.replace(/[$,]/g, ""));
      let temp = [];
      if (SelectedBedRoom !== "all") {
        temp =
          price >= newValue[0] &&
          price <= newValue[1] &&
          (SelectedBedRoom == "4"
            ? Number(item.beds) >= Number(SelectedBedRoom)
            : SelectedBedRoom !== "all"
            ? item.beds == SelectedBedRoom
            : null);
      } else {
        temp = price >= newValue[0] && price <= newValue[1];
      }

      return temp;
    });

    setAllRental([...tmp]);
  };

  const SortByBedroom = (e) => {
    let tmpArray = AllRental.length > 1 ? AllRental : AllInitialRental;
    let bedrooms = e.target.value;

    setSelectedBedRoom(bedrooms);
    // setbedroomFilters(bedrooms);
    setprice([0, 10000]);
    let tmp = [];
    tmp = tmpArray.filter((item) => {
      if (bedrooms == "4") {
        return Number(item.beds) >= Number(bedrooms);
      }
      return item.beds == bedrooms;
    });

    if (tmp.length == 0) {
      tmp = AllInitialRental.filter((item) => {
        if (bedrooms == "4") {
          return Number(item.beds) >= Number(bedrooms);
        }
        return item.beds == bedrooms;
      });
    }
    setAllRental(tmp);
  };

  const SortByCity = (e) => {
    let tmpArray = AllRental.length > 1 ? AllRental : AllInitialRental;
    let selected_city = e.target.value;
    setSelectedCity(selected_city);

    let tmp = tmpArray.filter((item) => {
      let city = item?.locationAddress?.city;
      return city === selected_city;
    });

    setAllRental(tmp);
  };

  const getRentalList = async () => {
    setLoading(true);
    try {
      const resDefault = await RentalsListingService.getRentalList();
      // console.log(
      //   `🚀 ~ file: index.jsx:124 ~ getRentalList ~ resDefault:`,
      //   resDefault
      // );

      setAllRental(resDefault);
      setAllInitialRental([...resDefault]);
    } catch (error) {
      toast.error(error);
    }
    setLoading(false);
  };

  const ResetFilter = () => {
    setAllRental([...AllInitialRental]);
    setSelectedCity("all");
    setSelectedPriceSort("all");
    setprice([0, 10000]);
    setSelectedBedRoom("all");
  };

  const AllBedrooms = ["Studio", "1", "2", "3", "4"];

  useEffect(() => {
    getRentalList();
  }, []);

  // Sort by Price
  const getValue = (price) => +price.slice(1) || 0;
  const SortByPrice = (e) => {
    let tmpArray = AllRental.length > 1 ? AllRental : AllInitialRental;
    let sortType = e.target.value;
    setSelectedPriceSort(sortType);
    if (sortType === "low_to_high") {
      let SortedArray = tmpArray.sort((a, b) => {
        let a_price = a.price.replace(/[,]/g, "");
        let b_price = b.price.replace(/[,]/g, "");
        return getValue(a_price) - getValue(b_price);
      });

      setAllRental([...SortedArray]);
    } else if (sortType === "high_to_low") {
      let SortedArray = tmpArray.sort((a, b) => {
        let a_price = a.price.replace(/[,]/g, "");
        let b_price = b.price.replace(/[,]/g, "");
        return getValue(b_price) - getValue(a_price);
      });

      setAllRental([...SortedArray]);
    }
  };

  // const Sortbedroombyprice = (event, newValue) => {
  //   console.log(
  //     `🚀 ~ file: index.jsx:208 ~ Sortbedroombyprice ~ event:`,
  //     event
  //   );
  //   console.log(
  //     `🚀 ~ file: index.jsx:208 ~ Sortbedroombyprice ~ newValue:`,
  //     newValue
  //   );

  //   setPriceSliderLabelText(
  //     "$" +
  //       Number(newValue[0]).toLocaleString() +
  //       " - " +
  //       "$" +
  //       Number(newValue[1]).toLocaleString()
  //   );
  //   setprice(newValue);

  //   let tmp = AllInitialRental.filter((item) => {
  //     let price = Number(item.price.replace(/[$,]/g, ""));

  //     if (bedroomFilters == "4") {
  //       return (
  //         price >= newValue[0] &&
  //         price <= newValue[1] &&
  //         Number(item.beds) >= Number(bedroomFilters)
  //       );
  //     }

  //     return (
  //       price >= newValue[0] &&
  //       price <= newValue[1] &&
  //       item.beds == bedroomFilters
  //     );
  //   });

  //   setAllRental(tmp);
  // };

  // Sort by Price
  const SortByDate = (e) => {
    let tmpArray = AllInitialRental;
    let selectedDate = e.target.value;
    let tmp = tmpArray.filter((item) => {
      let date = new Date(item?.createdAt.seconds * 1000)
        .toISOString()
        .split("T")[0];
      return date === selectedDate;
    });
    setAllRental(tmp);
  };

  return (
    <Layouts fullwidth={true}>
      <div className="tw-flex sm:tw-hidden tw-justify-between tw-items-center tw-px-3 tw-py-2 tw-bg-[#3a63d3] dark:tw-text-white ">
        <p className="tw-text-white">Filters</p>
        <div
          className="tw-cursor-pointer tw-text-white"
          aria-hidden="true"
          onClick={() => {
            setShowFilters(!ShowFilters);
          }}
        >
          {ShowFilters ? <SlArrowUp /> : <SlArrowDown />}
        </div>
      </div>

      <div
        className={`${
          ShowFilters ? "tw-block" : "tw-hidden"
        } sm:tw-block w-full tw-bg-[#3a63d3] `}
      >
        <div className="tw-flex tw-flex-wrap tw-items-center tw-justify-around sm:tw-gap-8 container">
          <div className="tw-px-3 tw-min-w-[200px]">
            <label className="tw-label " htmlFor="city_select">
              <span className="tw-label-text tw-text-white">City</span>
            </label>
            <select
              id="city_select"
              className="tw-select tw-w-full tw-max-w-xs"
              value={SelectedCity}
              onChange={SortByCity}
            >
              {/* <option disabled selected>
                City
              </option> */}
              <option disabled selected value={"all"}>
                All
              </option>
              <option value={"Miami"}>Miami, FL</option>
            </select>
          </div>

          <div className="tw-px-3  tw-min-w-[200px]">
            <label className="tw-label " htmlFor="bedroom_options">
              <span className="tw-label-text tw-text-white">Bedroom</span>
            </label>
            {/* <div className="tw-btn-group tw-flex">
              {AllBedrooms.map((item, index) => (
                // <input
                //   key={index}
                //   className="tw-join-item tw-btn tw-mt-0 tw-ml-0 tw-px-2 tw-py-2 bedroom_options"
                //   type="radio"
                //   name="bedroom_options"
                //   id="bedroom_options"
                //   aria-label={item == "4" ? "4+" : item}
                //   checked={SelectedBedRoom == index}
                //   value={item}
                //   onChange={(e) => {
                //     SortByBedroom(e, index);
                //   }}
                // />
                <input
                  key={index}
                  type="radio"
                  name="bedroom_options"
                  data-title={item == "4" ? "4+" : item}
                  value={item}
                  checked={SelectedBedRoom == index}
                  className="tw-btn tw-mt-0 tw-ml-0 tw-px-2 tw-py-2 bedroom_options"
                  onChange={(e) => {
                    SortByBedroom(e, index);
                  }}
                />
              ))}
            </div> */}

            <select
              id="bedroom_options"
              className="tw-select tw-w-full tw-max-w-xs"
              onChange={SortByBedroom}
              value={SelectedBedRoom}
            >
              <option disabled selected value={"all"}>
                All
              </option>
              {AllBedrooms.map((item, index) => (
                <option
                  key={index}
                  value={item}
                  selected={SelectedBedRoom == item}
                  className="tw-capitalize"
                >
                  {item == "4" ? "4+" : item}
                </option>
              ))}
            </select>
          </div>

          <div className="tw-px-3 tw-min-w-[200px] tw-text-white">
            <label className="tw-label " htmlFor="price_range">
              <span className="tw-label-text tw-text-white">Price</span>
            </label>
            <Slider
              id="price_range"
              sx={{ color: "#fff" }}
              value={price}
              // getAriaValueText={(value) => {
              //   console.log(`🚀 ~ file: index.jsx:294 ~ RentalListings ~ value:`, value);
              //   let tmp = "$" + value.toLocaleString();
              //   setPriceSliderLabelText(tmp);
              // }}
              max={10000}
              valueLabelFormat={PriceSliderLabelText}
              min={0}
              onChange={
                // bedroomFilters == "" ? SortByPriceRange : Sortbedroombyprice
                SortByPriceRange
              }
              valueLabelDisplay="auto"
            />
            <label className="tw-label tw-pt-0" htmlFor="price_range">
              <span className="tw-label-text-alt tw-text-white">
                Min ${Number(price[0]).toLocaleString()}
              </span>
              <span className="tw-label-text-alt tw-text-white">
                Max ${Number(price[1]).toLocaleString()}
              </span>
            </label>
          </div>

          {/* <div className="tw-px-3 tw-py-2 tw-min-w-[200px]">
            <label className="tw-label " htmlFor="date_added">
              <span className="tw-label-text tw-text-white">Date Added</span>
            </label>
            <input
              id="date_added"
              type="date"
              name="date_added"
              className="tw-input tw-input-bordered tw-input-md tw-w-full tw-max-w-xs tw-bg-transparent"
              onChange={SortByDate}
            />
          </div> */}

          <div className="tw-px-3  tw-min-w-[200px]">
            <label className="tw-label " htmlFor="Sort_select">
              <span className="tw-label-text tw-text-white">Sort By</span>
            </label>
            <select
              id="Sort_select"
              className="tw-select tw-w-full tw-max-w-xs"
              value={SelectedPriceSort}
              onChange={SortByPrice}
            >
              <option disabled selected value={"all"}>
                All
              </option>
              {/* <option value={"all"}>All</option> */}
              <option value={"low_to_high"}>Low to High</option>
              <option value={"high_to_low"}>High to Low</option>
            </select>
          </div>
          <div className="tw-px-3 tw-py-2 tw-min-w-[200px] tw-h-[100px] flex tw-justify-center tw-items-center">
            <button
              className="tw-btn tw-btn-error tw-py-3 tw-px-4"
              onClick={ResetFilter}
            >
              Reset
            </button>
          </div>
        </div>
      </div>

      {Loading ? (
        <div className="tw-h-[80vh] flex tw-justify-center tw-items-center">
          <CircularProgress />
        </div>
      ) : (
        <div className="flex tw-w-full sm:tw-w-5/6 tw-mx-auto tw-justify-center tw-px-2">
          {AllRental.length == 0 ? (
            <div className="flex tw-flex-col tw-items-center  tw-w-full tw-py-8">
              <img
                src="/not_found.png"
                alt="not found"
                width={"200"}
                className=""
              />
              <p className="no_rental_found">No rental listings were found</p>
            </div>
          ) : (
            <div className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-3 tw-gap-5 tw-place-content-center tw-my-5 tw-border-4 tw-border-white tw-text-black ">
              {AllRental?.map((item, index) => (
                <Link
                  to={`/rental/${item.id}`}
                  className="tw-no-underline "
                  key={index}
                >
                  <Card
                    sx={{ maxWidth: 345 }}
                    className="tw-cursor-pointer rental_cards dark:tw-outline tw-outline-offset-2 tw-outline-1 tw-outline-[#0c2233]"
                  >
                    <div className="tw-p-3 tw-pb-0">
                      <CardMedia
                        sx={{ height: 250, maxWidth: 400 }}
                        // image="https://cdn.pixabay.com/photo/2016/11/18/17/20/living-room-1835923_1280.jpg"
                        image={item.images[0]}
                        title="green iguana"
                        className="tw-rounded-lg"
                      />
                    </div>

                    <CardContent>
                      <p className="tw-text-2xl tw-font-semibold tw-pb-1 rental_texts">
                        {item.price}
                      </p>
                      <p className="tw-py-1 rental_texts">
                        <MdRoom /> {item.locationAddress.address},{" "}
                        {item.locationAddress.city},{" "}
                        {item.locationAddress.state},{" "}
                        {item.locationAddress.zipCode}
                      </p>

                      <div className="tw-py-1 rental_texts flex tw-flex-wrap tw-gap-3 tw-justify-between tw-items-center tw-my-3">
                        <div className="flex tw-items-center tw-gap-2">
                          <IoBedOutline />
                          <p>{item?.beds} Bedroom</p>
                        </div>
                        <div className="flex tw-items-center tw-gap-2">
                          <BiBath />
                          <p>{item?.bath} Bathroom</p>
                        </div>
                        <div className="flex tw-items-center tw-gap-2">
                          <MdSettingsOverscan />
                          <p>{Number(item?.sqft).toLocaleString()} Sq.Ft.</p>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </Link>
              ))}
            </div>
          )}
        </div>
      )}
    </Layouts>
  );
};

export default RentalListings;
