import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { AddressAutofill } from '@mapbox/search-js-react';

import { Layouts, MySelect, StatesSelect } from 'src/components';
import RentalsService from 'src/services/rentals';
import { AddRentalsValidationSchema } from 'src/validations/rentals';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../config/firebase';

import StarRating from '../../components/StarRating';
import { STATES_OPTIONS } from '../../components/StatesSelect';
import 'react-datepicker/dist/react-datepicker.css';
import 'src/styles/addrental.css';


const AddRental = () => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [configsItems, setConfigsItems] = useState();
	const [rating, setRating] = useState(0);

	const {
		register,
		handleSubmit,
		reset,
		setValue,
		control,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(AddRentalsValidationSchema),
	});

	const onSubmit = async data => {
		setLoading(true);
		try {
			const rentalData = {
				name: data.name,
				email: data.email,
				phoneNumber: data.phoneNumber,
				comments: data.comments,
				includedUtilities: data.include_utilites.map(e => e.value),
				baths: data.baths.value,
				beds: data.beds.value,
				propertyType: data.property_type.value,
				moveInDate: data.move_in,
				price: data.price,
				coordinates: data.coordinates,
				locationAddress: {
					address: data.address,
					city: data.city,
					state: data.state,
					zipCode: data.zip_code,
				},
			};

			if (rating) {
				rentalData.satisfactionRating = rating;
			}

			if (data.move_out) {
				rentalData.moveOutDate = data.move_out;
			}

    	await RentalsService.createRental(rentalData);
			toast.success('New rental created');
			navigate('/');
		} catch (error) {
			toast.error(error);
		}
		setLoading(false);
	};

	useEffect(() => {
		const getConfigs = async() => {
			setLoading(true);
			try {
				const configs = await RentalsService.getConfigs();
				setConfigsItems(configs);
			} catch (error) {
				toast.error(error);
			}
			setLoading(false);
		};

		getConfigs();
	}, []);

	const handleReset = () => {
		reset({
			comments: null,
			include_utilites: null,
			baths: null,
			beds: null,
			property_type: null,
			move_out: null,
			move_in: null,
			price: '',
			coordinates: null,
			address: '',
			city: null,
			state: null,
			zip_code: null,
		});

    logEvent(analytics, 'add_rental_reset');
	};

	return (
		<Layouts>
			<div className='container add-rental'>
				<div className='flex items-center justify-between'>
					<div>
						<h2>Add Your Rental</h2>
						<p>
              Share your rental information and join the movement to bring
              transparency to the rental market.
						</p>
					</div>
					<button
						onClick={ () => handleReset() }
						disabled={ loading }
						className='btn btn-danger'>
            Reset
					</button>
				</div>
				<form
					style={ { marginTop: '24px' } }
					className='card grid grid-col-3 tw-shadow-md'>
					<div className='form-input col-span-3'>
						<label htmlFor='address'> Address </label>
						<AddressAutofill
							onRetrieve={ e => {
                // const address = e.features[0].properties;
                // const stateCode = address.address_level1;
                // const zipCode = address.postcode;

                // // Find the corresponding state option
                // const stateOption = STATES_OPTIONS.find(option => option.value === stateCode);

                // setValue('state', stateOption);
                // setValue('zip_code', zipCode);

								setValue('coordinates', e.features[0].geometry.coordinates, {
									shouldValidate: true,
								});
								setValue('address', e.features[0].properties.feature_name, {
									shouldValidate: true,
								});
							} }
							options={ {
								country: 'us',
							} }
							accessToken={ process.env.REACT_APP_MAPBOX_ACCESS_TOKEN }>
							<input
								id='address'
								placeholder='Address'
								type='text'
								{ ...register('address') }
								autoComplete='street-address'
							/>
						</AddressAutofill>
						{ errors.address && (
							<p className='text-error'>{ errors.address.message }</p>
						) }
						{ errors.coordinates && (
							<p className='text-error'>{ errors.coordinates.message }</p>
						) }
					</div>
					<div className='form-input'>
						<label htmlFor='city'> City </label>
						<input
							id='city'
							{ ...register('city') }
							placeholder='City'
							autoComplete='address-level2'
						/>
						{ errors.city && <p className='text-error'>{ errors.city.message }</p> }
					</div>
					<div className='form-input'>
          <label htmlFor='state'> State </label>
						<input
							id='state'
							placeholder='State'
							{ ...register('state') }
							autoComplete='address-level1'
						/>
						{ errors.state && (
							<p className='text-error'>{ errors.state.message }</p>
						) }
					</div>
					<div className='form-input'>
						<label htmlFor='zip_code'> Zip Code </label>
						<input
							id='zip_code'
							{ ...register('zip_code') }
							name='zip_code'
							autoComplete='postal-code'
							placeholder='Zip Code'
						/>
						{ errors.zip_code && (
							<p className='text-error'>{ errors.zip_code.message }</p>
						) }
					</div>
					<div className='col-span-3 '>
						<br />
						<hr />
						<br />
					</div>
					<div className='col-span-3 grid grid-col-2'>
						<div className='form-input tw-relative'>
							<label htmlFor='move_in'> Move In </label>
							<Controller
								name='move_in'
								control={ control }
								render={ ({ field }) => (
									<DatePicker
										onChange={ date => field.onChange(date) }
										selected={ field.value }
										showMonthYearPicker
										dateFormat='MMMM yyyy'
										placeholderText='Month - Year'
									/>
								) }
							/>
							{ errors.move_in && (
								<p className='text-error'>{ errors.move_in.message }</p>
							) }
						</div>
						<div className='form-input tw-relative'>
							<label htmlFor='move_out'>
								{ ' ' }
                Move Out <span> (If current, leave blank)</span>
							</label>
							<Controller
								name='move_out'
								control={ control }
								render={ ({ field }) => (
									<DatePicker
										onChange={ date => field.onChange(date) }
										selected={ field.value }
										showMonthYearPicker
										dateFormat='MMMM yyyy'
										placeholderText='Month - Year'
									/>
								) }
							/>
							{ errors.move_out && (
								<p className='text-error'>{ errors.move_out.message }</p>
							) }
						</div>
					</div>
					<div className='tw-col-span-3 tw-grid tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-[10px]'>
						<div className='form-input max-lg:tw-col-span-3'>
							<label htmlFor='property_type'> Property Type </label>
							<MySelect
								control={ control }
								isError={ errors.property_type }
								id='property_type'
								{ ...register('property_type') }
								options={ configsItems?.propertyType?.map(e => {
									return {
										label: e,
										value: e,
									};
								}) }
							/>
							{ errors.property_type && (
								<p className='text-error'>
									{ errors.property_type.message ??
                    errors.property_type?.value?.message }
								</p>
							) }
						</div>
						<div className='form-input'>
							<label htmlFor='beds'> Beds </label>
							<MySelect
								control={ control }
								isError={ errors.beds }
								id='beds'
								options={ configsItems?.numberBeds?.map(e => {
									return {
										label: e,
										value: e,
									};
								}) }
							/>
							{ errors.beds && (
								<p className='text-error'>
									{ errors.beds.message ?? errors.beds?.value?.message }
								</p>
							) }
						</div>
						<div className='form-input'>
							<label htmlFor='baths'> Baths </label>
							<MySelect
								control={ control }
								isError={ errors.baths }
								id='baths'
								options={ configsItems?.numberBaths?.map(e => {
									return {
										label: e,
										value: e,
									};
								}) }
							/>
							{ errors.baths && (
								<p className='text-error'>
									{ errors.baths.message ?? errors.baths?.value?.message }
								</p>
							) }
						</div>
					</div>
					<div className='col-span-3'>
						<div className='grid grid-col-2'>
							<div className='form-input'>
								<label htmlFor='price'>
									{ ' ' }
                  Price <span>(per month)</span>{ ' ' }
								</label>
								<Controller
									name='price'
									control={ control }
									render={ ({ field }) => (
										<NumericFormat
											{ ...field }
											placeholder='$'
											thousandSeparator={ true }
											prefix={ '$' }
										/>
									) }
								/>
								{ errors.price && (
									<p className='text-error'>{ errors.price.message }</p>
								) }
							</div>
							<div className='form-input'>
								<label htmlFor='include_utilites'>
									{ ' ' }
                  What utilities are included?
								</label>
								<MySelect
									control={ control }
									isError={ errors.include_utilites }
									id='include_utilites'
									isMulti
									options={ configsItems?.includedUtilities?.map(e => {
										return {
											label: e,
											value: e,
										};
									}) }
								/>
								{ errors.include_utilites && (
									<p className='text-error'>
										{ errors.include_utilites.message ??
                      errors.include_utilites?.value?.message }
									</p>
								) }
							</div>
						</div>
					</div>
					<div className='col-span-3'>
						<div className='form-input'>
							<label htmlFor='comments'>
								{ ' ' }
                Comments <span>(optional)</span>{ ' ' }
							</label>
							<textarea
								id='comments'
								{ ...register('comments') }
								placeholder='Comments'
								rows={ 5 }
							/>
							{ errors.comments && (
								<p className='text-error'>{ errors.comments.message }</p>
							) }
						</div>
					</div>
					<div className='col-span-3 '>
						<br />
					  <StarRating onRatingChange={ newRating => setRating(newRating) } />
					</div>
					<div className='col-span-3 '>
						<br />
						<hr />
						<br />
					</div>
					<div className='col-span-3 grid grid-col-2'>
						<div className='form-input'>
							<label htmlFor='name'>
								{ ' ' }
                Name <span>(optional)</span>{ ' ' }
							</label>
							<input
								id='name'
								type='text'
								{ ...register('name') }
								placeholder='Name'
							/>
							{ errors.name && (
								<p className='text-error'>{ errors.name.message }</p>
							) }
						</div>
						<div className='form-input'>
							<label htmlFor='email'>
								{ ' ' }
                Email <span>(optional)</span>{ ' ' }
							</label>
							<input
								id='email'
								type='email'
								{ ...register('email') }
								placeholder='Email'
							/>
							{ errors.email && (
								<p className='text-error'>{ errors.email.message }</p>
							) }
						</div>
					</div>
					<div className='col-span-3'>
						<div className='form-input'>
							<label htmlFor='phoneNumber'>
								{ ' ' }
                Phone Number <span>(optional)</span>{ ' ' }
							</label>
							<input
								id='phoneNumber'
								type='text'
								{ ...register('phoneNumber') }
								placeholder='Phone Number'
							/>
							{ errors.phoneNumber && (
								<p className='text-error'>{ errors.phoneNumber.message }</p>
							) }
						</div>
						<p
							style={ {
								fontSize: '11px',
								fontStyle: 'italic',
								marginTop: '8px',
							} }>
              * Your contact info helps you save in future
							<br />* We will not spam you
						</p>
					</div>

					<div className='col-span-3'>
						<button
							onClick={ handleSubmit(onSubmit) }
							disabled={ loading }
							style={ { marginLeft: 'auto', marginRight: 'auto', width: '30%' } }
							className='btn'>
              Submit
						</button>
					</div>
				</form>
			</div>
		</Layouts>
	);
};

export default AddRental;
